.direct {
  @include fluidValue(24px, 56px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 48px, 390px, 1440px, 20px, padding-bottom);

  &>:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    span {
      display: block;
      @include fluidValue(40px, 96px, 390px, 1440px, 40px, font-size);
    }
  }

  &:first-child .text {
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, font-size);
    font-weight: 600;
    line-height: 1.23;
  }

  &:last-child {
    background-color: $black;
    color: $white;

    .text {
      font-size: $text-xl;
      font-weight: 600;
      line-height: 1.23;
    }
  }
}