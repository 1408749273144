.how-it-works {
  @include fluidValue(24px, 56px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 30px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 30px, 390px, 1440px, 20px, padding-bottom);

  &>:not(:last-child) {
    @include fluidValue(24px, 50px, 390px, 1440px, 24px, margin-bottom);
  }

  &__title {
    @include fluidValue(36px, 70px, 390px, 1440px, 36px, font-size);
  }

  ol {
    padding-left: 1.75em;
    font-size: $text-l;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &__subtitle,
  ol li::marker {
    font-size: calc($text-xl * 1.17);
    font-weight: 700;
  }

  .more-link {
    margin-top: auto;
  }
}