.goals {
  @include fluidValue(24px, 56px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 30px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 30px, 390px, 1440px, 20px, padding-bottom);
  background-color: $black;
  color: $white;

  &>:not(:last-child) {
    @include fluidValue(24px, 40px, 390px, 1440px, 24px, margin-bottom);
  }

  &__title {
    @include fluidValue(36px, 70px, 390px, 1440px, 36px, font-size);
  }

  ul {
    @include list-reset;
    @include fluidValue(24px, 45px, 390px, 1440px, 24px, font-size);
    font-weight: 700;

    li {
      padding-left: 1.1em;
      position: relative;

      &::before {
        content: '';
        width: 0.675em;
        height: 0.675em;
        background-image: url(./../images/sprites/main.stack.svg#image-check);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0.33em;
        left: 0;
      }
    }
  }

  .more-link {
    margin-top: auto;
  }
}