.why-choose {
  @include fluidValue(24px, 45px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 35px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 26px, 390px, 1440px, 20px, padding-bottom);
  background-color: $black;
  color: $white;

  &>:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    @include fluidValue(36px, 65px, 390px, 1440px, 36px, font-size);
  }

  &__list {
    font-size: $text-l;

    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    display: block;
    font-size: calc($text-xl * 1.17);
    font-weight: 700;
  }

  .button {
    margin-top: auto;
    align-self: flex-end;
  }
}