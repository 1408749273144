.webinars {
  &__grid {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto;
    row-gap: 12px;
    column-gap: 12px;

    @include lg-max {
      grid-template-columns: 55% 45%;
    }

    @include sm-max {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    justify-content: space-between;
    @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-top);
    @include fluidValue(16px, 24px, 390px, 1440px, 16px, padding-right);
    @include fluidValue(16px, 36px, 390px, 1440px, 16px, padding-left);
    padding-bottom: 22px;
    background-color: $black;
    color: $white;

    &>:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .featured-webinar {
    @include sm-min {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }
  }

  .person {
    &__image {
      border: 4px solid transparent;
      background-image: $gradient-main;
      @include fluidValue(60px, 98px, 390px, 1440px, 60px, width);
      @include fluidValue(60px, 98px, 390px, 1440px, 60px, height);

      @include sm-max {
        border-width: 2px;
      }

      img{
        transform: scale(1.05);
      }
    }

    &__main {
      font-size: $text-xl;
    }

    &__name {
      font-weight: 400;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__card-footer {
    display: flex;
    justify-content: space-between;

    @include xs-max {
      flex-direction: column;
    }

    .more-link {
      margin-left: 10px;
      align-self: flex-end;

      @include xs-max {
        margin-top: 16px;
      }
    }
  }

  &__date {
    display: block;
  }

  &__time {
    display: block;
  }
}