@font-face
    font-family: 'Play'
    font-weight: 400
    font-display: swap
    src: url('../fonts/Play-Regular.woff') format('woff'), url('../fonts/Play-Regular.ttf') format('truetype')
@font-face
    font-family: 'Play'
    font-weight: 700
    font-display: swap
    src: url('../fonts/Play-Bold.woff') format('woff'), url('../fonts/Play-Bold.ttf') format('truetype')
@font-face
    font-family: 'Satoshi'
    font-weight: 300
    font-display: swap
    src: url('../fonts/Satoshi-Light.woff') format('woff'), url('../fonts/Satoshi-Light.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 300
    font-style: italic
    font-display: swap
    src: url('../fonts/Satoshi-LightItalic.woff') format('woff'), url('../fonts/Satoshi-LightItalic.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 400
    font-display: swap
    src: url('../fonts/Satoshi-Regular.woff') format('woff'), url('../fonts/Satoshi-Regular.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 400
    font-style: italic
    font-display: swap
    src: url('../fonts/Satoshi-Italic.woff') format('woff'), url('../fonts/Satoshi-Italic.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 500
    font-display: swap
    src: url('../fonts/Satoshi-Medium.woff') format('woff'), url('../fonts/Satoshi-Medium.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 500
    font-style: italic
    font-display: swap
    src: url('../fonts/Satoshi-MediumItalic.woff') format('woff'), url('../fonts/Satoshi-MediumItalic.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 700
    font-display: swap
    src: url('../fonts/Satoshi-Bold.woff') format('woff'), url('../fonts/Satoshi-Bold.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 700
    font-style: italic
    font-display: swap
    src: url('../fonts/Satoshi-BoldItalic.woff') format('woff'), url('../fonts/Satoshi-BoldItalic.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 900
    font-display: swap
    src: url('../fonts/Satoshi-Black.woff') format('woff'), url('../fonts/Satoshi-Black.otf') format('opentype')
@font-face
    font-family: 'Satoshi'
    font-weight: 900
    font-style: italic
    font-display: swap
    src: url('../fonts/Satoshi-BlackItalic.woff') format('woff'), url('../fonts/Satoshi-BlackItalic.otf') format('opentype')