.content-grid {
  .section-title {
    @include fluidValue(36px, 42px, 390px, 1440px, 36px, font-size);
  }

  &__wrap {
    @include sm-min {
      background-color: $white;
      @include fluidValue(20px, 42px, 390px, 1440px, 20px, border-radius);
      box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.09);
      overflow: hidden;
    }
  }

  &__row {
    display: flex;

    @include sm-max {
      display: block;
      background-color: $white;
      @include fluidValue(20px, 42px, 390px, 1440px, 20px, border-radius);
      box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.09);
      overflow: hidden;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    &:not(:last-child) {
      @include sm-max {
        margin-bottom: 24px;
      }
    }
  }

  &__image {
    width: 50%;
    overflow: hidden;

    @include sm-max {
      width: 100%;
      aspect-ratio: 16/9;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    width: 50%;
    display: flex;
    flex-direction: column;
    @include fluidValue(24px, 62px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 55px, 390px, 1440px, 16px, padding-inline);

    @include sm-max {
      width: 100%;
    }
  }

  &__title {
    @include fluidValue(32px, 45px, 390px, 1440px, 32px, font-size);
    margin-bottom: 30px;
  }

  &__descr {
    @include fluidValue(20px, 40px, 390px, 1440px, 20px, font-size);
    line-height: 1;
    margin-bottom: auto;
  }

  .inline-link {
    margin-top: 30px;
  }
}