.case-details {
  max-width: 915px;
  width: 100%;
  margin: 0 auto;

  .tags {
    @include fluidValue(24px, 44px, 390px, 1440px, 24px, margin-bottom);
  }

  &__head {
    display: flex;
    align-items: center;
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, margin-bottom);

    @include xs-max {
      display: block;
    }
  }

  &__image {
    flex-shrink: 0;
    max-width: 100%;
    @include fluidValue(190px, 300px, 390px, 1440px, 190px, width);
    @include fluidValue(160px, 250px, 390px, 1440px, 160px, height);
    @include fluidValue(20px, 35px, 390px, 1440px, 20px, border-radius);
    overflow: hidden;
    @include fluidValue(20px, 30px, 390px, 1440px, 20px, margin-right);

    @include xs-max {
      margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    @include fluidValue(36px, 50px, 390px, 1440px, 36px, font-size);
    letter-spacing: -0.02em;
  }

  &__author-box {
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, margin-bottom);
  }

  &__name {
    display: block;
    font-weight: 600;
  }

  &__position {
    display: block;
  }

  &__content {
    font-size: $text-l;

    h2 {
      font-size: 1.4em;
    }

    h3 {
      font-size: 1.3em;
    }

    h4 {
      font-size: 1.2em;
    }

    h5 {
      font-size: 1.1em;
    }

    h6 {
      font-size: 1em;
    }

    blockquote {
      font-weight: 600;
      font-style: italic;
      border-left: 2px solid $black;
      margin-left: 0;
      padding-block: 0.25em;
      padding-left: 0.75em;
    }
  }
}

.case-graph {
  @include fluidValue(16px, 32px, 390px, 1440px, 16px, padding-block);
  @include fluidValue(8px, 24px, 390px, 1440px, 8px, padding-inline);
  border: 1px solid #D9D9D9;
  @include fluidValue(24px, 76px, 390px, 1440px, 24px, border-radius);

  &__row {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      @include fluidValue(12px, 20px, 390px, 1440px, 12px, margin-bottom);
    }

    &:nth-child(even) {
      .case-graph__tag {
        background-image: $gradient-main;
      }
    }
  }

  &__tag {
    flex-shrink: 0;
    max-width: 220px;
    @include fluidValue(100px, 220px, 390px, 1440px, 100px, max-width);
    width: 100%;
    @include fluidValue(20px, 38px, 390px, 1440px, 20px, padding-block);
    padding-inline: 12px;
    border-radius: 100px;
    background-color: #E2E1E4;
    @include fluidValue(16px, 30px, 390px, 1440px, 16px, font-size);
    letter-spacing: 0.14em;
    text-align: center;
    text-transform: uppercase;
    @include fluidValue(10px, 42px, 390px, 1440px, 10px, margin-right);
  }

  &__text {
    @include fluidValue(24px, 50px, 390px, 1440px, 24px, font-size);
    font-weight: 700;
    line-height: 1.15;
    letter-spacing: -0.02em;
  }
}