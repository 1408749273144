.featured-webinar {
  @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-block);
  @include fluidValue(16px, 32px, 390px, 1440px, 16px, padding-inline);

  &>:not(:last-child) {
    @include fluidValue(30px, 50px, 390px, 1440px, 30px, margin-bottom);
  }

  &__box {
    &>:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__caption {
    display: block;
    font-weight: 500;
  }

  &__title {
    @include fluidValue(36px, 65px, 390px, 1440px, 36px, font-size);
  }

  &__subtitle {
    display: block;
  }

  .text {
    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .button {
    margin-top: auto;
  }
}