.error
    padding-bottom: 88px
    +max($width_md)
        padding-bottom: 177px
    &__heading
        +max($width_md)
            margin-top: 64px
        +max($width_xs)
            margin-top: 24px
    &__logo
        width: 58%
        margin: 70px auto 72px
        position: relative
        z-index: $index-1
        +max($width_md)
            width: 100%
            margin: 116px auto
        +max($width_xs)
            margin: 72px auto 80px
        &::before
            content: ""
            display: block
            width: 70%
            height: 165%
            top: 50%
            left: 50%
            position: absolute
            background-image: url(../images/radar.png)
            background-size: contain
            background-repeat: no-repeat
            background-position: center center
            z-index: -1
            opacity: .9
            transform: translate(-50%,-50%)
        img
            display: block
            width: 100%
    &__info
        display: flex
        flex-direction: column
        align-items: center
        font-family: $Play
        line-height: 1.3
        font-size: $font24
        color: $white
        text-align: center
        margin: 0 auto
        +max($width_md)
            font-size: $font22
        +max($width_xs)
            font-size: $font14
        .btn
            margin-top: 32px
            +max($width_md)
                margin-top: 40px
            +max($width_xs)
                padding: 8px 32px
                min-height: 48px