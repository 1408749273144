@import "./normalize";
@import "./vars";
@import "./mixins";
@import "./fonts";
@import "./popup";
// @import "./sprite_generated";

// From libs: 
@import "../libs/@splidejs/splide/dist/css/splide-core.min.css";
@import "../libs/glightbox/dist/css/glightbox.min.css";

:root {
  @include fluidValue(14px, 16px, 390px, 1440px, 14px, --text-s); // 16px
  @include fluidValue(16px, 18px, 390px, 1440px, 16px, --text-m); // 18px
  @include fluidValue(18px, 20px, 390px, 1440px, 18px, --text-l); // 20px
  @include fluidValue(20px, 24px, 390px, 1440px, 20px, --text-xl); // 24px
  @include fluidValue(24px, 32px, 390px, 1440px, 24px, --text-xxl); // 32px
}

*,
::before,
::after {
  box-sizing: border-box;
}

// * {
//   min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()
// }

// html {
//   scroll-padding-top: 72px;
// }

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $bg;
  font-family: $font-default;
  font-size: $text-m;
  font-weight: 400;
  line-height: 1.33;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  position: relative;
  padding-top: 69px;

  // @include lg-max {
  //   padding-top: 100px;
  // }

  &.lock {
    overflow: hidden;
  }
}

.main {
  flex-grow: 1;
  // @include fluidValue(30px, 50px, 390px, 1440px, 30px, padding-top);
  padding-bottom: 90px;
}

.container {
  width: 100%;
  max-width: 1192px;
  margin: 0 auto;
  @include fluidValue(18px, 24px, 390px, 1440px, 18px, padding-inline);
}

.logo {
  display: block;

  img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

h1,
.h1 {
  @include fluidValue(40px, 80px, 390px, 1440px, 40px, font-size); // 80px -> 40
  letter-spacing: -0.03em;
}

h2,
.h2 {
  @include fluidValue(36px, 48px, 390px, 1440px, 36px, font-size); // 48px -> 36
}

h3,
.h3 {
  @include fluidValue(32px, 40px, 390px, 1440px, 32px, font-size); // 40px -> 32
}

h4,
.h4 {
  @include fluidValue(24px, 32px, 390px, 1440px, 24px, font-size); // 32px -> 24
}

h5,
.h5 {
  @include fluidValue(20px, 28px, 390px, 1440px, 20px, font-size); // 28px -> 20
}

h6,
.h6 {
  @include fluidValue(18px, 24px, 390px, 1440px, 18px, font-size); // 24px -> 18
}

.text-s {
  font-size: $text-s;
}

.text-m {
  font-size: $text-m;
}

.text-l {
  font-size: $text-l;
}

.text-xl {
  font-size: $text-xl;
}

.text-xxl {
  font-size: $text-xxl;
}

.text {

  p,
  ul,
  ol {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  ul,
  ol {
    padding-left: 24px;
  }

  ul {
    list-style-type: disc;
  }
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.25s;
}

.button {
  display: block;
  width: max-content;
  max-width: 100%;
  padding: 14px 32px;
  border-radius: 200px;
  border: none;
  background-image: $gradient-main;
  font-size: $text-s;
  font-weight: 700;
  line-height: 1.18;
  text-align: center;
  letter-spacing: -0.02em;
  color: $white;
  transition: color 0.25s, border-color 0.25s, background-color 0.25s;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  @include hasHover {
    transform: scale(1.02);
  }

  &--secondary {
    background-image: $gradient-second;
  }

  &--white {
    background-image: none;
    background-color: $white;
    color: $black;
  }

  &--black {
    background-image: none;
    background-color: $black;
  }

  &--l {
    @include fluidValue(16px, 21px, 390px, 1440px, 16px, padding-block);
    @include fluidValue(32px, 40px, 390px, 1440px, 32px, padding-inline);
    font-size: $text-l;
    font-weight: 500;
  }

  &--xl {
    @include fluidValue(16px, 21px, 390px, 1440px, 16px, padding-block);
    @include fluidValue(32px, 40px, 390px, 1440px, 32px, padding-inline);
    font-size: $text-xxl;
    font-weight: 600;
  }
}

.inactive {
  pointer-events: none;
}

.text-gradient {
  background: -webkit-linear-gradient(45deg, #2CBF5C 0%, #48DF84 48%, #C3E813 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-secondary {
  background: -webkit-linear-gradient(-30deg, #7549F2 0%, #DF58D2 48%, #ED787C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  display: block;
  max-width: 100%;
  object-fit: cover;
}

section,
.section {
  padding: 28px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.section-title {
  text-align: center;
  margin-bottom: 30px;

  &--sm {
    @include fluidValue(36px, 42px, 390px, 1440px, 36px, font-size);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 12px;
  column-gap: 12px;

  @include sm-max {
    grid-template-columns: 1fr;
  }

  &--3 {
    @include sm-min {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--60-40 {
    @include sm-min {
      grid-template-columns: 60% 40%;

      @include lg-max {
        grid-template-columns: 55% 45%;
      }
    }
  }

  &--40-60 {
    @include sm-min {
      grid-template-columns: 40% 60%;

      @include lg-max {
        grid-template-columns: 45% 55%;
      }
    }
  }
}

.more-link {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  font-size: $text-xl;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 0.01em;
  column-gap: 8px;

  .icon {
    flex-shrink: 0;
    transition: transform 0.25s;
  }

  @include hasHover {
    .icon {
      transform: scale(1.075);
    }
  }
}

.inline-link {
  display: inline-block;
  font-size: $text-l;
  font-weight: 500;
  color: $blue;
  transition: color 0.25s;

  @include hasHover {
    color: #1b70cf;
  }
}

.card {
  display: flex;
  flex-direction: column;
  @include fluidValue(20px, 42px, 390px, 1440px, 20px, border-radius);
  background-color: $white;
  box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.09);
  overflow: hidden;

  @include lg-max {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
  }
}

.lang-switcher {
  display: flex;
  align-items: center;
  position: relative;

  @include hasHover {
    .lang-switcher__current span {
      background: -webkit-linear-gradient(45deg, #2CBF5C 0%, #48DF84 48%, #C3E813 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__current {
    display: flex;
    align-items: center;
    cursor: pointer;

    &>:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__list {
    display: none;
    width: 100%;
    padding: 4px 8px 4px 26px;
    background-color: black;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  &__item {
    display: block;
    margin-bottom: 4px;
    cursor: pointer;

    @include hasHover {
      background: -webkit-linear-gradient(45deg, #2CBF5C 0%, #48DF84 48%, #C3E813 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.open .lang-switcher__list {
    display: block;
  }
}

.tags {
  @include list-reset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @include fluidValue(8px, 15px, 390px, 1440px, 8px, --gap);
  row-gap: var(--gap);
  column-gap: var(--gap);

  button,
  .tags__link {
    display: inline-block;
    border: none;
    @include fluidValue(8px, 13px, 390px, 1440px, 8px, padding-block);
    @include fluidValue(16px, 21px, 390px, 1440px, 16px, padding-inline);
    border-radius: 100px;
    background-color: $white;
    font-size: $text-s;
    color: $black;
    white-space: nowrap;
    cursor: pointer;
    transition: color 0.25s, background-color 0.25s;

    &.is-active {
      background-color: $black;
      color: $white;
    }
  }

  .tags__current {
    background-color: $black;
    color: $white;
    cursor: auto;
  }
}
