.alert
    margin-bottom: 112px
    position: relative
    z-index: $index-1
    +max($width_md)
        margin-bottom: 80px
    +max($width_xs)
        margin-bottom: 56px
    &.alert--alt
        margin-bottom: 120px
        +max($width_md)
            margin-bottom: 80px
        +max($width_xs)
            margin-bottom: 56px
        .alert__wrapper
            padding: 80px
            +max($width_md)
                padding: 64px
            +max($width_xs)
                padding: 40px 24px
        .alert__heading
            +max($width_xs)
                margin-left: 0
                margin-right: 0
    &__wrapper
        padding: 120px 124px
        background-image: url(../images/bg/bg-alert.png)
        background-repeat: no-repeat
        background-position: center bottom
        background-size: cover
        position: relative
        border-radius: 32px
        +max($width_md)
            padding: 80px 64px
        +max($width_xs)
            padding: 56px 32px
        &::before, &::after
            content: ""
            display: block
            position: absolute
            z-index: -1
        &::before
            top: 0
            right: 0
            bottom: 0
            left: 0
            margin: -1px
            border-radius: inherit
            background: linear-gradient(to bottom, $transparent, rgba(156, 91, 222, 0.67))
        &::after
            width: 40%
            top: 0
            bottom: 0
            left: 50%
            transform: translateX(-50%)
            border-radius: 32px
            box-shadow: 0 0 100px 70px rgba(182, 114, 251, 0.15)
    &__heading
        text-align: center
        +max($width_xs)
            margin-left: -16px
            margin-right: -16px
        h2
            margin-bottom: 16px
            +max($width_xs)
                margin-bottom: 8px
            &:last-child
                margin-bottom: 0
        .btn
            margin-top: 40px
        br
            +max($width_xs)
                display: none
    &__buttons
        margin-top: 40px
        display: flex
        justify-content: center
        +max($width_xs)
            margin-top: 24px
            flex-direction: column
        > *
            margin-right: 40px
            +max($width_md)
                margin-right: 24px
            +max($width_xs)
                margin-right: 0
                margin-bottom: 8px
            &:last-child
                margin-right: 0
                +max($width_xs)
                    margin-bottom: 0
    &__info
        justify-content: space-between
        --bs-gutter-x: 60px
        --bs-gutter-y: 56px
        margin-top: calc(var(--bs-gutter-y) * -1 + 99px)
        +max($width_md)
            margin-top: calc(var(--bs-gutter-y) * -1 + 56px)
            flex-direction: column-reverse
            align-items: center
        +max($width_xs)
            margin-top: calc(var(--bs-gutter-y) * -1 + 32px)
            --bs-gutter-y: 32px
        .subheading
            text-align: left
        ul
            margin-top: 15px
            margin-bottom: 72px
            +max($width_md)
                margin-top: 0
                margin-bottom: 56px
            +max($width_xs)
                margin-bottom: 40px
            li
                margin-bottom: 32px
                padding-left: 18px
                position: relative
                +max($width_md)
                    margin-bottom: 16px
                &::before
                    content: ""
                    display: block
                    width: 0
                    height: 0
                    border-style: solid
                    border-width: 5px 0 5px 10px
                    border-color: transparent transparent transparent $pink
                    position: absolute
                    top: 10.5px
                    left: 0
                    +max($width_xs)
                        top: 4px
                &:last-child
                    margin-bottom: 0
    &__connections
        display: block
        width: 100%