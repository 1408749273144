input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea
    display: block
    width: 100%
    height: 54px
    border: none
    background: $gray
    font-size: $font16
    font-weight: 500
    line-height: 1.3
    border-radius: 4px
    color: $dark2
    padding: 10px 20px
    +max($width_xs)
        font-size: $font14
        height: 48px
    &::placeholder
        color: $light
textarea
    height: 150px
    resize: none