.start-tenders {
  @include fluidValue(24px, 50px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-left);
  @include fluidValue(16px, 20px, 390px, 1440px, 16px, padding-right);
  @include fluidValue(20px, 55px, 390px, 1440px, 20px, padding-bottom);
  background-color: $black;
  color: $white;

  @include sm-min {
    width: calc(55% - 6px);
  }

  &>:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    line-height: 0.9;
  }

  &__descr {
    @include fluidValue(32px, 40px, 390px, 1440px, 32px, font-size);
    font-weight: 600;
    line-height: 1.05;
  }

  .text {
    @include fluidValue(20px, 26px, 390px, 1440px, 20px, font-size);

    span {
      display: block;
      font-weight: 600;
    }
  }
}