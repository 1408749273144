:root
    --ss-content-height: 332px
    --ss-main-height: 38px
.ss-image
    width: 24px
    margin-right: 8px
    flex-shrink: 0
.ss-icon
    width: 19px
    margin-right: 8px
    flex-shrink: 0
.ss-main
    align-items: center
    border-radius: 0
    border: none
    background: $transparent
    color: $white
    text-transform: uppercase
    font-size: $font14
    line-height: 1.2
    padding: 0
    &:focus
        box-shadow: none
    &.ss-open-below, &.ss-open-above
        .ss-arrow
            transform: rotate(180deg)
    .ss-values
        flex-grow: 1
        overflow: hidden
        text-overflow: ellipsis
        .ss-placeholder
            padding: 0
            line-height: inherit
            color: inherit
        .ss-placeholder, .ss-single
            display: flex
            align-items: center
            margin: auto 0
    .ss-arrow
        width: 24px
        height: 24px
        margin: auto 0 auto 4px
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 10C16.7239 9.72386 16.2761 9.72386 16 10L12 14L8 10C7.72386 9.72386 7.27614 9.72386 7 10C6.72386 10.2761 6.72386 10.7239 7 11L12 16L17 11C17.2761 10.7239 17.2761 10.2761 17 10Z' fill='white'/%3E%3C/svg%3E%0A")
        background-repeat: no-repeat
        background-position: center center
        background-size: contain
        flex-shrink: 0
        *
            display: none
.ss-content
    border: none
    background: $dark
    border-radius: 8px
    padding: 6px 0
    &:not(.ss-content-auto-width)
        width: auto !important
    font-size: $font14
    line-height: 1.2
    text-transform: uppercase
    &.ss-open-below
        transform: scaleY(1) translateY(17px)
        +max($width_md)
            transform: scaleY(1) translateY(0)
    &.ss-open-above
        transform: scaleY(1) translateY(0)
    &.ss-open-above, &.ss-open-below
        border-radius: 8px
    .ss-list
        position: relative
        padding: 2px 8px
        &.ps--active-y
            padding-right: 11px
        .ss-option
            display: flex
            align-items: center
            padding: 5px 24px
            border-radius: 4px
            background: $transparent
            color: $white
            min-height: 36px
            margin-bottom: 4px
            &:hover
                background: $pink
            &.ss-highlighted, &:not(.ss-disabled).ss-selected
                background: $transparent
                color: $light
                &:hover
                    background: $pink
            &.ss-option--sm
                padding: 5px 16px
