input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea
    display: block
    width: 100%
    height: 54px
    border: none
    background: $bg
    font-size: $text-m
    font-weight: 500
    line-height: 1.3
    border-radius: 4px
    color: $black
    padding: 10px 20px
    +xs-max()
        font-size: $text-s
        height: 48px
    &::placeholder
        color: $gray
textarea
    height: 150px
    resize: none
