.next-case {
  &__link {
    display: flex;
    align-items: center;
    max-width: 915px;
    width: 100%;
    margin: 0 auto;

    @include xs-max {
      display: block;
    }

    &:hover {
      .next-case__image img {
        transform: scale(1.05);
      }
    }
  }

  &__image {
    flex-shrink: 0;
    max-width: 100%;
    @include fluidValue(160px, 250px, 390px, 1440px, 160px, width);
    @include fluidValue(160px, 250px, 390px, 1440px, 160px, height);
    @include fluidValue(20px, 35px, 390px, 1440px, 20px, border-radius);
    overflow: hidden;
    @include fluidValue(20px, 48px, 390px, 1440px, 20px, margin-right);

    @include xs-max {
      margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.25s;
    }
  }

  &__caption {
    display: block;
    font-size: $text-l;
    font-weight: 500;
    letter-spacing: -0.02em;
    @include fluidValue(12px, 22px, 390px, 1440px, 12px, margin-bottom);
  }

  &__title {
    max-width: 440px;
    @include fluidValue(24px, 35px, 390px, 1440px, 24px, font-size);
    letter-spacing: -0.02em;
    transition: color 0.25s;
  }
}