.header
    position: absolute
    top: 0
    left: 0
    right: 0
    width: 100%
    color: $white
    text-transform: uppercase
    font-size: $font14
    line-height: 1.2
    &__wrapper
        display: flex
        align-items: center
        justify-content: space-between
        padding: 16px 0
    &__logo
        display: block
        width: 104px
        +max($width_xs)
            width: 78px
        img
            display: block
            width: 100%
    &__nav
        display: flex
        align-items: center
        +max($width_md)
            display: none
        > *
            &:last-child
                margin-right: 0
    &__menu
        display: flex
        align-items: center
        flex-wrap: wrap
        margin-right: 103px
        text-transform: uppercase
        +max($width_xl)
            margin-right: 50px
        +max($width_lg)
            margin-right: 40px
        +max($width_md)
            margin-right: 0
            flex-direction: column
            align-items: stretch
            line-height: 1.3
            color: $white
            font-weight: 500
        +max($width_xs)
            flex-grow: 1
            text-align: center
        > li
            margin-right: 64px
            +max($width_xl)
                margin-right: 40px
            +max($width_lg)
                margin-right: 20px
            +max($width_md)
                margin-right: 0
            +max($width_xs)
                margin-bottom: 8px
            &:last-child
                margin-right: 0
                +max($width_xs)
                    margin-bottom: 0
            &.active
                > a
                    color: $light2
                    +max($width_md)
                        color: $pink
                    &:hover, &:active
                        color: $light2
            > a
                padding: 10px 8px
                +max($width_xl)
                    padding: 0
                +max($width_md)
                    display: block
                    padding: 20px 0
                +max($width_xs)
                    padding: 12px 0
            > a
                &:hover, &:active
                    color: $pink
    &__language
        margin-right: 76px
        +max($width_xl)
            margin-right: 50px
        +max($width_lg)
            margin-right: 40px
        +max($width_md)
            margin-top: 13px
            margin-right: 0
        +max($width_xs)
            margin-top: 0
    &__buttons
        display: flex
        +max($width_xs)
            flex-grow: 1
            flex-direction: column
        > *:not([hidden])~:not([hidden])
            margin-inline-start: 16px
            +max($width_xs)
                margin-inline-start: 0
                margin-top: 16px
    &__mobile
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: $index-4
        display: none
        flex-direction: column
        &.active
            display: flex
        &-open, &-close
            display: none
            cursor: pointer
            width: 32px
            height: 32px
            color: $white
            +max($width_md)
                display: block
            .icon
                width: 100%
        &-top
            margin-bottom: 56px
            +max($width_xs)
                margin-bottom: 0
        &-header
            display: flex
            padding: 16px 0
        &-subheader
            margin-top: 8px
            display: none
            +max($width_xs)
                display: flex
        &-header, &-subheader
            align-items: center
            justify-content: space-between
        &-main
            display: flex
            justify-content: space-between
            margin-bottom: 32px
            +max($width_xs)
                margin-bottom: 0
            .header__language
                +max($width_xs)
                    display: none
        &-wrapper
            display: flex
            flex-direction: column
            height: 100%
            padding-bottom: 64px
            overflow-y: auto
            color: $white
            +max($width_xs)
                padding-bottom: 24px
                justify-content: space-between
        &-bottom
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: auto
            +max($width_xs)
                margin-top: 0
            .header__country
                +max($width_xs)
                    display: none
        > .container
            flex-grow: 1