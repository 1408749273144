.tenders {
  @include fluidValue(24px, 42px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-left);
  @include fluidValue(16px, 20px, 390px, 1440px, 16px, padding-right);
  @include fluidValue(20px, 48px, 390px, 1440px, 20px, padding-bottom);

  @include sm-min {
    width: calc(45% - 6px);
  }

  &>:not(:last-child) {
    @include fluidValue(24px, 36px, 390px, 1440px, 24px, margin-bottom);
  }

  &__title {
    letter-spacing: -0.05em;
    line-height: 0.9;
  }

  &__subtitle {
    letter-spacing: -0.03em;
    margin-bottom: 12px;
  }

  .text {
    font-weight: 500;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .button {
    margin-top: auto;
  }
}