.footer {
  @include fluidValue(50px, 95px, 390px, 1440px, 50px, padding-block);
  background-color: $black;
  color: $white;

  .logo {
    height: 47px;
    @include fluidValue(40px, 60px, 390px, 1440px, 40px, margin-bottom);

    @include sm-max {
      height: 38px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 30px;
    @include fluidValue(40px, 100px, 390px, 1440px, 40px, margin-bottom);

    @include sm-max {
      flex-wrap: wrap;
    }
  }

  &__descr {
    width: 50%;

    @include sm-max {
      width: 100%;
    }
  }

  &__nav {
    ul {
      @include list-reset;
      font-size: 16px;
    }

    li:not(:last-child) {
      margin-bottom: 16px;

      @include sm-max {
        margin-bottom: 12px;
      }
    }
  }

  &__subtitle {
    display: block;
    font-weight: 700;
    margin-bottom: 16px;

    @include sm-max {
      margin-bottom: 12px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    font-size: 16px;
    column-gap: 16px;
    row-gap: 30px;

    @include md-max {
      align-items: flex-start;
      flex-direction: column;
    }

    .socials {
      margin-left: auto;

      @include md-max {
        order: -1;
        margin-left: 0;
      }
    }
  }

  .lang-switcher {
    margin-right: 44px;
  }

  &__sidemenu {
    @include list-reset;
    display: flex;
    align-items: center;

    @include md-max {
      display: block;
    }

    li:not(:last-child) {
      margin-right: 60px;

      @include md-max {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }

  a {
    @include hasHover {
      background: -webkit-linear-gradient(45deg, #2CBF5C 0%, #48DF84 48%, #C3E813 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.socials {
  @include list-reset;
  display: flex;
  align-items: center;

  li:not(:last-child) {
    @include fluidValue(20px, 32px, 390px, 1440px, 20px, margin-right);
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    @include md-max {
      width: 20px;
      height: 20px;
    }

    @include hasHover {
      color: $accent;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}