@keyframes ai-circle
    0%
        transform: rotate(0deg) translateX(41.19%) rotate(0deg)
    100%
        transform: rotate(360deg) translateX(41.19%) rotate(-360deg)
@keyframes ai-radar
    0%
        transform: rotate(0deg) scale(1)
        opacity: .9
    25%
        transform: rotate(90deg) scale(.75)
        opacity: .25
    50%
        transform: rotate(180deg) scale(1)
        opacity: .9
    75%
        transform: rotate(270deg) scale(.75)
        opacity: .25
    100%
        transform: rotate(360deg) scale(1)
        opacity: .9
@keyframes ai-radar--mini
    0%
        transform: rotate(0deg) scale(1)
        opacity: .7
    25%
        transform: rotate(90deg) scale(.75)
        opacity: .25
    50%
        transform: rotate(180deg) scale(1)
        opacity: .7
    75%
        transform: rotate(270deg) scale(.75)
        opacity: .25
    100%
        transform: rotate(360deg) scale(1)
        opacity: .7
.ai
    margin: 0 auto
    width: 31.41%
    position: relative
    z-index: $index-1
    flex-shrink: 0
    +max($width_md)
        width: 41.56%
    +max($width_xs)
        width: 46.15%
    &-logo
        display: block
        width: 100%
        height: auto
    &-circle
        display: block
        width: 23.13%
        background: linear-gradient(214deg, #BD3F86 13.33%, #9A367F 84.1%)
        border-radius: 50%
        position: absolute
        top: 37.96%
        left: 38.435%
        animation: ai-circle 5s linear infinite
        animation-play-state: paused
        &::after
            content: ""
            display: block
            padding-bottom: 100%
    &-radar
        width: 140%
        height: 140%
        top: 50%
        left: 50%
        margin-top: -70%
        margin-left: -70%
        position: absolute
        background-image: url(../images/radar.png)
        background-size: contain
        background-repeat: no-repeat
        background-position: center center
        z-index: -1
        animation: ai-radar 10s linear infinite
        animation-play-state: paused
        opacity: .9
        +max($width_xxl)
            width: 200%
            height: 200%
            margin-top: -100%
            margin-left: -100%
        +max($width_xs)
            width: 170%
            height: 170%
            margin-top: -85%
            margin-left: -85%
        &.ai-radar--mini
            width: 140%
            height: 140%
            margin-top: -70%
            margin-left: -70%
            top: 54%
            background-image: url(../images/radar--mini.png)
            opacity: .7
            animation-name: ai-radar--mini
    &-ellipses
        position: absolute
        top: 55%
        left: 54%
        transform: translate(-50%,-50%)
        width: 185.98%
        z-index: $index-2
        &.active
            +min($width_md)
                > svg
                    path
                        opacity: .2
                        &.active
                            opacity: 1
        > svg
            display: block
            width: 100%
            height: auto
            path
                opacity: 1
                transition: $transition
                &:hover
                    ~ path
                        opacity: .2
        &__item
            width: 50px
            height: 50px
            border-radius: 50%
            position: absolute
            +max($width_xs)
                width: 18px
                height: 18px
            &::before, &::after
                content: ""
                display: block
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
                border-radius: 50%
            &::before
                width: 34px
                height: 34px
                +max($width_xs)
                    width: 12px
                    height: 12px
            &::after
                width: 10px
                height: 10px
                background: $white
                +max($width_xs)
                    width: 4px
                    height: 4px
            &.active
                +min($width_md)
                    .ai-ellipses__item-title
                        opacity: 1
            &:nth-child(1)
                top: 12%
                left: 9.5%
                background: rgba(156, 91, 222, .2)
                +max($width_lg)
                    left: 8%
                +max($width_xs)
                    left: 10%
                &::before
                    background: rgba(156, 91, 222, 1)
            &:nth-child(2)
                top: 52.5%
                left: 88%
                background: rgba(234, 76, 150, .2)
                +max($width_lg)
                    top: 51%
                +max($width_xs)
                    top: 53%
                    left: 90%
                &::before
                    background: rgba(234, 76, 150, 1)
                &.active
                    +min($width_md)
                        .ai-ellipses__item-wrapper
                            padding-right: 21px
                .ai-ellipses__item-wrapper
                    left: auto
                    right: 22px
                    transform: translate(100%,100%)
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='301' height='27' viewBox='0 0 301 27' fill='none'%3E%3Cpath d='M300.5 26.5H53.5L1 1' stroke='white' stroke-dasharray='2 2'/%3E%3C/svg%3E")
                    background-position: left top
                    padding-right: 0
                    padding-left: 90px
                    +max($width_lg)
                        padding-left: 60px
                    +max($width_md)
                        text-align: right
                    +max($width_xs)
                        right: 10px
                        padding-left: 0
                        background-position: left 10% top
            &:nth-child(3)
                top: 75%
                left: 9.5%
                background: rgba(135, 41, 255, .2)
                +max($width_lg)
                    left: 8%
                +max($width_xs)
                    left: 9%
                    top: 80%
                &::before
                    background: rgba(135, 41, 255, 1)
            &-wrapper
                position: absolute
                bottom: 25px
                left: 22px
                transform: translate(-100%,100%)
                padding-top: 33px
                padding-right: 90px
                font-size: $font14
                line-height: 1.5
                color: $white
                background-image: url("data:image/svg+xml,%3Csvg style='transform: scale(-1,1)' xmlns='http://www.w3.org/2000/svg' width='301' height='27' viewBox='0 0 301 27' fill='none'%3E%3Cpath d='M300.5 26.5H53.5L1 1' stroke='white' stroke-dasharray='2 2'/%3E%3C/svg%3E")
                background-repeat: no-repeat
                background-position: right top
                background-size: auto 26px
                white-space: nowrap
                min-width: 108px
                +max($width_lg)
                    padding-right: 60px
                +max($width_md)
                    min-width: 100px
                +max($width_xs)
                    min-width: 38px
                    background-position: right 10% top
                    background-size: auto 18px
                    padding-right: 0
                    bottom: 16px
                    left: 10px
                    font-size: $font12
                    padding-top: 22px
                > *
                    display: block
                    margin-bottom: 4px
                    &:last-child
                        margin-bottom: 0
            &-index
                font-weight: 500
                line-height: 1.2
            &-title
                transition: $transition
                overflow: hidden
                max-width: 0
                +max($width_md)
                    display: none