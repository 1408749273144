.sourcing
    overflow: hidden
    &__heading
        color: $light3
        position: relative
        z-index: $index-2
        +max($width_xxl)
            margin-top: -32px
        +max($width_md)
            margin-top: -16px
        > *
            margin-bottom: 32px
            +max($width_md)
                margin-bottom: 24px
            +max($width_xs)
                margin-bottom: 8px
            &:last-child
                margin-bottom: 0
    &__items
        display: flex
        align-items: center
        justify-content: space-between
        margin: 132px 0
        +max($width_xxl)
            margin: 95px 0
        +max($width_md)
            margin: 99px 0 80px
            flex-wrap: wrap
        +max($width_xs)
            margin: 40px 0 32px
    &__item
        flex: 1 1 0
        position: relative
        z-index: $index-2
        text-align: left
        +max($width_md)
            flex: 0 1 auto
            max-width: 48%
        &-title
            display: block
            margin-bottom: 24px
            color: $pink
            +max($width_xs)
                margin-bottom: 16px
        .subheading
            text-align: inherit
        br
            +max($width_xs)
                display: none
    &__ai
        margin: 0 10.07%
        width: 31.53%
        +max($width_xxl)
            margin: 0 10.47%
            width: 23.67%
        +max($width_md)
            order: -1
            margin: 0 30% 107px
            width: 40%
        +max($width_xs)
            margin: 0 28% 42px
            width: 44%
        &-arrow
            display: block
            width: 37px
            height: 43px
            background: $pink
            position: absolute
            top: 50%
            background: linear-gradient(270deg, #EA4C96 0%, rgba(234, 76, 150, 0.00) 102.7%)
            clip-path: polygon(0 0, 0% 100%, 100% 50%)
            +max($width_xs)
                width: 24px
                height: 28px
            &:first-child
                left: -12%
                transform: translate(-100%,-50%) rotate(180deg)
                +max($width_xxl)
                    left: -15%
                +max($width_md)
                    left: -62%
                +max($width_xs)
                    left: -45%
            &:last-child
                right: -12%
                transform: translate(100%,-50%)
                +max($width_xxl)
                    right: -15%
                +max($width_md)
                    right: -62%
                +max($width_xs)
                    right: -45%
        &:hover
            .ai
                &-circle, &-radar
                    @media (hover: hover)
                        animation-play-state: running
        .ai
            &-circle, &-radar
                @media (hover: none)
                    animation-play-state: running