.hero-home {
  padding-bottom: 0;
  position: relative;

  &__wrap {
    // max-width: 1240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-right: calc(-50vw + 50%);

    @include sm-max {
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__main {
    width: 55%;
    padding-block: 24px;

    @include sm-max {
      width: 100%;
    }

    &>:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title {
    @include fluidValue(40px, 100px, 390px, 1440px, 40px, font-size);
    transition: transform 0.75s ease 0.2s, opacity 0.75s ease 0.2s;

    @media (min-width: 1441px) {
      font-size: 120px;
    }

    span {
      display: block;
    }
  }

  .text {
    max-width: 500px;
    @include fluidValue(20px, 30px, 390px, 1440px, 20px, font-size);
    font-weight: 500;
    transition: transform 0.75s ease 0.8s, opacity 0.75s ease 0.8s;

    @media (min-width: 1441px) {
      font-size: 38px;
    }

    @include sm-max {
      max-width: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 12px;
    transition: transform 0.75s ease 1.05s, opacity 0.75s ease 1.05s;

    @include sm-max {
      flex-wrap: wrap;
      column-gap: 12px;
    }

    .button {
      @include fluidValue(14px, 20px, 390px, 1440px, 14px, padding-block);
      @include fluidValue(20px, 36px, 390px, 1440px, 20px, padding-inline);
      @include fluidValue(16px, 28px, 390px, 1440px, 16px, font-size);
      font-weight: 600;
    }
  }

  &__image {
    width: 43%;

    @include sm-max {
      max-width: 500px;
    }
  }
}

// body:not(.loaded) {
//   .hero__title {
//     transform: translateY(50px);
//     opacity: 0;
//   }

//   .hero__video {
//     transform: scale(0.65);
//     opacity: 0;
//   }

//   .hero__descr,
//   .hero__buttons {
//     transform: scale(0.85);
//     opacity: 0;
//   }
// }