@mixin r($width)
    @media only screen and (max-width: $width)
        @content
@mixin rh($height)
    @media only screen and (max-height: $height)
        @content

@mixin max($width)
    @media (max-width: $width)
        @content

@mixin min($width)
    @media (min-width: $width + 0.02px)
        @content

@mixin minmax($min, $max)
    @media (min-width: $min + 0.02px) and (max-width: $max)
        @content

@mixin xl
    @media (min-width: 1600px)
        @content

@mixin lg
    @media (min-width: 1300px) and (max-width: 1599px)
        @content

@mixin md
    @media (min-width: 1000px) and (max-width: 1299px)
        @content

@mixin sm
    @media (min-width: 700px) and (max-width: 999px)
        @content

@mixin xs
    @media (max-width: 699px)
        @content


@mixin font-face($font-name,$font-file)
    @font-face
        font-family: '#{$font-name}'
        font-display: swap
        src: url('../fonts/#{$font-file}.woff') format('woff'), url('../fonts/#{$font-file}.woff2') format('woff2')


// Fluid fonts
// See https://habr.com/post/310186/
// Also check _layout.sass, fix for Safari is required

@function strip-unit($number)
    @if type-of($number) == 'number' and not unitless($number)
        @return $number / ($number * 0 + 1)
    @return $number
@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px)
    $f-min: strip-unit($f-min)
    $f-max: strip-unit($f-max)
    $w-min: strip-unit($w-min)
    $w-max: strip-unit($w-max)

    $k: ($f-max - $f-min)/($w-max - $w-min)
    $b: $f-min - $k * $w-min

    $b: $b + $units

    @return calc( #{$k} * 100vw + #{$b} )

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false)
    font-size: $f-min
    @media (min-width: $w-min)
        @if ($fallback)
            font-size: $fallback
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px)
    @media (min-width: $w-max)
        font-size: $f-max