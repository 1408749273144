.main
    flex-grow: 1
    display: flex
    flex-direction: column
    > *:first-child
        padding-top: 152px
        +max($width_md)
            padding-top: 120px
        +max($width_xs)
            padding-top: 88px
        &:last-child
            flex-grow: 1