@use 'sass:math';

%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-ae {
	@extend %svg-common;
	background-position: 94.94949494949495% 60.46511627906977%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-ae {
	background-position: 94.94949494949495% 60.46511627906977%;
}
@mixin svg-image-ae-hover {
	.svg-image-ae {
		@include svg-pos-image-ae-hover
	}
}

.svg-image-ae:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-ae-dims {
	width: 21px;
	height: 16px;
}

.svg-image-bullish {
	@extend %svg-common;
	background-position: 0 44.73684210526316%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-bullish {
	background-position: 0 44.73684210526316%;
}
@mixin svg-image-bullish-hover {
	.svg-image-bullish {
		@include svg-pos-image-bullish-hover
	}
}

.svg-image-bullish:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-bullish-dims {
	width: 26px;
	height: 26px;
}

.svg-image-close {
	@extend %svg-common;
	background-position: 0 0;
	background-size: math.div(120, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-close {
	background-position: 0 0;
}
@mixin svg-image-close-hover {
	.svg-image-close {
		@include svg-pos-image-close-hover
	}
}

.svg-image-close:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-close-dims {
	width: 34px;
	height: 34px;
}

.svg-image-code {
	@extend %svg-common;
	background-position: 27.659574468085108% 44.73684210526316%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-code {
	background-position: 27.659574468085108% 44.73684210526316%;
}
@mixin svg-image-code-hover {
	.svg-image-code {
		@include svg-pos-image-code-hover
	}
}

.svg-image-code:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-code-dims {
	width: 26px;
	height: 26px;
}

.svg-image-energy {
	@extend %svg-common;
	background-position: 72.34042553191489% 0;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-energy {
	background-position: 72.34042553191489% 0;
}
@mixin svg-image-energy-hover {
	.svg-image-energy {
		@include svg-pos-image-energy-hover
	}
}

.svg-image-energy:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-energy-dims {
	width: 26px;
	height: 26px;
}

.svg-image-gem {
	@extend %svg-common;
	background-position: 72.34042553191489% 34.21052631578947%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-gem {
	background-position: 72.34042553191489% 34.21052631578947%;
}
@mixin svg-image-gem-hover {
	.svg-image-gem {
		@include svg-pos-image-gem-hover
	}
}

.svg-image-gem:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-gem-dims {
	width: 26px;
	height: 26px;
}

.svg-image-id {
	@extend %svg-common;
	background-position: 94.94949494949495% 79.06976744186046%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-id {
	background-position: 94.94949494949495% 79.06976744186046%;
}
@mixin svg-image-id-hover {
	.svg-image-id {
		@include svg-pos-image-id-hover
	}
}

.svg-image-id:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-id-dims {
	width: 21px;
	height: 16px;
}

.svg-image-in {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-in {
	background-position: 0 100%;
}
@mixin svg-image-in-hover {
	.svg-image-in {
		@include svg-pos-image-in-hover
	}
}

.svg-image-in:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-in-dims {
	width: 21px;
	height: 16px;
}

.svg-image-language {
	@extend %svg-common;
	background-position: 0 78.94736842105263%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-language {
	background-position: 0 78.94736842105263%;
}
@mixin svg-image-language-hover {
	.svg-image-language {
		@include svg-pos-image-language-hover
	}
}

.svg-image-language:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-language-dims {
	width: 26px;
	height: 26px;
}

.svg-image-like {
	@extend %svg-common;
	background-position: 27.659574468085108% 78.94736842105263%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-like {
	background-position: 27.659574468085108% 78.94736842105263%;
}
@mixin svg-image-like-hover {
	.svg-image-like {
		@include svg-pos-image-like-hover
	}
}

.svg-image-like:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-like-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu {
	@extend %svg-common;
	background-position: 39.53488372093023% 0;
	background-size: math.div(120, 34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-menu {
	background-position: 39.53488372093023% 0;
}
@mixin svg-image-menu-hover {
	.svg-image-menu {
		@include svg-pos-image-menu-hover
	}
}

.svg-image-menu:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(34, 34)*100%;
}

.svg-image-menu-dims {
	width: 34px;
	height: 34px;
}

.svg-image-my {
	@extend %svg-common;
	background-position: 21.21212121212121% 100%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-my {
	background-position: 21.21212121212121% 100%;
}
@mixin svg-image-my-hover {
	.svg-image-my {
		@include svg-pos-image-my-hover
	}
}

.svg-image-my:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-my-dims {
	width: 21px;
	height: 16px;
}

.svg-image-place {
	@extend %svg-common;
	background-position: 55.319148936170215% 78.94736842105263%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-place {
	background-position: 55.319148936170215% 78.94736842105263%;
}
@mixin svg-image-place-hover {
	.svg-image-place {
		@include svg-pos-image-place-hover
	}
}

.svg-image-place:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-place-dims {
	width: 26px;
	height: 26px;
}

.svg-image-protection {
	@extend %svg-common;
	background-position: 100% 0;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-protection {
	background-position: 100% 0;
}
@mixin svg-image-protection-hover {
	.svg-image-protection {
		@include svg-pos-image-protection-hover
	}
}

.svg-image-protection:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-protection-dims {
	width: 26px;
	height: 26px;
}

.svg-image-star {
	@extend %svg-common;
	background-position: 100% 34.21052631578947%;
	background-size: math.div(120, 26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-star {
	background-position: 100% 34.21052631578947%;
}
@mixin svg-image-star-hover {
	.svg-image-star {
		@include svg-pos-image-star-hover
	}
}

.svg-image-star:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(26, 26)*100%;
}

.svg-image-star-dims {
	width: 26px;
	height: 26px;
}

.svg-image-tr {
	@extend %svg-common;
	background-position: 42.42424242424242% 100%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-tr {
	background-position: 42.42424242424242% 100%;
}
@mixin svg-image-tr-hover {
	.svg-image-tr {
		@include svg-pos-image-tr-hover
	}
}

.svg-image-tr:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-tr-dims {
	width: 21px;
	height: 16px;
}

.svg-image-uz {
	@extend %svg-common;
	background-position: 63.63636363636363% 100%;
	background-size: math.div(120, 21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-uz {
	background-position: 63.63636363636363% 100%;
}
@mixin svg-image-uz-hover {
	.svg-image-uz {
		@include svg-pos-image-uz-hover
	}
}

.svg-image-uz:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: math.div(16, 21)*100%;
}

.svg-image-uz-dims {
	width: 21px;
	height: 16px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }