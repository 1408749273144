.footer
    background: $dark3 url(../images/pattern.png) left top / 100px 100px repeat
    color: $white
    &__main
        padding: 56px 0 48px
        +max($width_md)
            padding: 56px 0 32px
        +max($width_xs)
            padding: 35px 0 32px
        &-wrapper
            display: flex
            align-items: center
            justify-content: space-between
            +max($width_md)
                flex-direction: column
                align-items: flex-start
            +max($width_xs)
                align-items: center
                text-align: center
    &__logo
        display: block
        width: 104px
        +max($width_xs)
            margin-left: auto
            margin-right: auto
            width: 78px
        &-caption
            margin-top: 8px
            color: $light
            font-size: $font14
            +max($width_xs)
                margin-top: 4px
                font-size: $font12
        &-wrapper
            +max($width_md)
                margin-bottom: 32px
            +max($width_xs)
                margin-bottom: 16px
        img
            display: block
            width: 100%
    &__nav
        display: flex
        align-items: center
        flex-wrap: wrap
        margin-top: -16px
        +max($width_xs)
            width: 100%
        > *
            margin-top: 16px
            +max($width_xs)
                margin-top: 24px
        > .btn
            +max($width_xs)
                width: 100%
                padding: 8px 32px
                min-height: 48px
    &__menu
        display: flex
        align-items: center
        flex-wrap: wrap
        margin-right: 72px
        font-size: $font14
        text-transform: uppercase
        line-height: 1.2
        margin-top: 8px
        +max($width_xl)
            margin-right: 50px
        +max($width_lg)
            margin-right: 40px
        +max($width_md)
            margin-right: 73px
        +max($width_xs)
            margin-right: 0
            flex-direction: column
            align-items: stretch
            width: 100%
            font-size: $font16
            line-height: 1.5
            margin-top: 16px
        > li
            margin-top: 8px
            margin-right: 48px
            +max($width_xl)
                margin-right: 40px
            +max($width_lg)
                margin-right: 20px
            +max($width_md)
                margin-right: 40px
            +max($width_xs)
                margin-top: 0
                margin-right: 0
            &:last-child
                margin-right: 0
            &.active
                > a
                    color: $light2
                    &:hover
                        color: $light2
            > a
                +max($width_xs)
                    display: block
                    padding: 12px 0
                &:hover
                    color: $pink
    &__info
        border-top: 1px solid rgba(156, 132, 188, 0.20)
        text-align: center
        font-size: $font12
        line-height: 1.4
        padding: 16px 0
        min-height: 66px
        +max($width_xs)
            font-size: $font10
        a
            &:hover, &:active
                color: $pink
        &-wrapper
            display: flex
            align-items: center
            justify-content: space-between
            +max($width_xs)
                flex-wrap: wrap
                align-items: flex-start
            > *
                margin-right: 8px
                &:last-child
                    margin-right: 0
            span
                display: block
                +max($width_xs)
                    display: inline
            .footer
                &__address
                    +max($width_lg)
                        flex-basis: 40%
                    +max($width_xs)
                        flex-basis: auto
                        width: 100%
                        flex-shrink: 0
                        margin-right: 0
                        margin-bottom: 8px
                        text-align: center
                        order: -1
                        span
                            margin-left: 4px
                            &:first-child
                                margin-left: 0
                    br
                        display: none
                &__copyright
                    +max($width_xs)
                        margin-top: 0
                        width: auto
                        order: 0
                        display: flex
                        flex-direction: row
                        justify-content: flex-start
                        align-items: center
                        > *
                            margin-right: 4px
                            &:last-child
                                margin-right: 0