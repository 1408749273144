.stats {
  @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 45px, 390px, 1440px, 20px, padding-bottom);

  flex-direction: row;
  justify-content: space-between;
  column-gap: 12px;

  @include sm-max {
    flex-direction: column;
    position: relative;
    padding-bottom: 105px;
  }

  &__main {
    @include sm-min {
      width: 55%;
    }

    @include sm-max {
      margin-bottom: 24px;
    }
  }

  &__second {
    @include sm-min {
      width: 45%;
    }
  }

  &__title {
    @include fluidValue(40px, 72px, 390px, 1440px, 40px, font-size);
    font-weight: 700;
    line-height: 0.95;
    letter-spacing: -0.02em;
  }

  .button {
    max-width: calc(100% - 32px);
    width: 392px;
    padding-bottom: 18px;
    @include fluidValue(20px, 28px, 390px, 1440px, 20px, font-size);
    font-weight: 600;
    @include fluidValue(24px, 40px, 390px, 1440px, 24px, margin-top);

    @include sm-max {
      margin-top: 0;
      position: absolute;
      left: 16px;
      bottom: 20px;
    }
  }

  &__list {
    @include fluidValue(18px, 22px, 390px, 1440px, 18px, font-size);
    font-weight: 500;

    li:not(:last-child) {
      margin-bottom: 1em;
    }

    span {
      display: block;
      @include fluidValue(40px, 64px, 390px, 1440px, 40px, font-size);
      font-weight: 700;
      line-height: 1;
    }
  }
}