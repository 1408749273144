.ps .ps__rail-y
    width: 3px
    border-radius: 25px
.ps .ps__thumb-y
    right: 0
    border-radius: 25px
.ps .ps__thumb-y,
.ps .ps__rail-y.ps--clicking .ps__thumb-y,
.ps .ps__rail-y:focus>.ps__thumb-y,
.ps .ps__rail-y:hover>.ps__thumb-y
    width: 100%
    background: $pink
.ps .ps--active-y
    padding-right: 0
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover,
.ps--focus>.ps__rail-x,
.ps--focus>.ps__rail-y,
.ps--scrolling-x>.ps__rail-x,
.ps--scrolling-y>.ps__rail-y,
.ps:hover>.ps__rail-x,
.ps:hover>.ps__rail-y,
.ps--active-y>.ps__rail-y
    background: rgba(255, 255, 255, 0.2)
    opacity: 1