.tenders-register {
  @include fluidValue(24px, 40px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-left);
  @include fluidValue(16px, 20px, 390px, 1440px, 16px, padding-right);
  @include fluidValue(20px, 50px, 390px, 1440px, 20px, padding-bottom);

  @include sm-min {
    width: calc(45% - 6px);
  }

  &>:not(:last-child) {
    @include fluidValue(24px, 40px, 390px, 1440px, 24px, margin-bottom);
  }

  &__list {
    font-size: $text-xl;

    li:not(:last-child) {
      margin-bottom: 12px;
    }

    span {
      display: block;
      @include fluidValue(22px, 28px, 390px, 1440px, 22px, font-size);
      font-weight: 600;
    }
  }

  .button {
    margin-top: auto;
  }
}