.tenders-grid {
  @include sm-min {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 24px;
  }

  @include sm-max {
    &>* {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}