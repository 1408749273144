.wins {
  &__head {
    text-align: center;

    .text {
      margin-top: 20px;
    }
  }

  &__num {
    display: block;
    @include fluidValue(44px, 150px, 390px, 1440px, 44px, font-size);
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.07em;

    @include xxs-max {
      font-size: 34px;
    }
  }
}

.wins-slider {
  margin-top: 30px;

  .tags {
    margin-bottom: 24px;
  }

  &__wrap {
    @include fluidValue(20px, 42px, 390px, 1440px, 20px, border-radius);
    background-color: $black;
    color: $white;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .splide__arrows {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 26px;

    @include sm-max {
      top: 12px;
      right: 12px;
    }
  }

  .splide__arrow {
    width: 45px;
    height: 45px;
    padding: 0;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $black;
    cursor: pointer;

    @include sm-max {
      width: 40px;
      height: 40px;
    }

    &--prev {
      .icon {
        transform: scale(-1, 1);
      }
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    &:not(:last-child) {
      margin-right: 8px;

      @include sm-max {
        margin-right: 4px;
      }
    }
  }

  .splide__slide {
    padding: 20px;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;

    &::before {
      @include sm-max {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, black 30%, rgba(0, 0, 0, 0.5) 100%);
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include fluidValue(24px, 70px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(0px, 44px, 768px, 1440px, 0px, padding-left);

    @include sm-max {
      width: 80%;
    }

    &>:not(:last-child) {
      @include fluidValue(24px, 70px, 390px, 1440px, 24px, margin-bottom);
    }
  }

  &__title {
    @include fluidValue(32px, 60px, 390px, 1440px, 32px, font-size);
  }

  &__subtitle {
    max-width: 330px;
    display: block;
    @include fluidValue(18px, 36px, 390px, 1440px, 18px, font-size);
    line-height: 1.1;
    margin-top: 10px;
  }

  &__num {
    display: block;
    @include fluidValue(80px, 250px, 390px, 1440px, 80px, font-size);
    font-weight: 700;
    line-height: 1;
    letter-spacing: -.0.7em;

    span {
      @include fluidValue(40px, 128px, 390px, 1440px, 40px, font-size);
    }
  }

  &__descr {
    display: block;
    max-width: 290px;
    font-weight: 500;
    letter-spacing: -0.03em;
  }
}