.platform {
  @include fluidValue(24px, 42px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-left);
  @include fluidValue(16px, 20px, 390px, 1440px, 16px, padding-right);
  @include fluidValue(20px, 48px, 390px, 1440px, 20px, padding-bottom);
  background-image: $gradient-main;
  color: $white;

  @include sm-min {
    width: calc(55% - 6px);
  }

  &>:not(:last-child) {
    @include fluidValue(24px, 36px, 390px, 1440px, 24px, margin-bottom);
  }

  &__title {
    @include fluidValue(40px, 70px, 390px, 1440px, 40px, font-size);
    line-height: 0.9;
  }

  &__list {
    @include fluidValue(20px, 30px, 390px, 1440px, 20px, font-size);
    font-weight: 600;

    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .button {
    margin-top: auto;
  }
}