.btn
    position: relative
    display: inline-flex
    align-items: center
    justify-content: center
    text-align: center
    padding: 7px 23px
    min-height: 36px
    border-radius: 30px
    text-transform: uppercase
    font-weight: 500
    line-height: 1.2
    font-size: $font14
    border: 1px solid $white
    cursor: pointer
    transition: $transition
    background: $transparent padding-box
    +max($width_xs)
        min-height: 40px
        width: 100%
    &:disabled, &.btn--disabled
        cursor: not-allowed
    &.btn
        &--primary
            background-color: $pink
            border-color: $pink
            color: $white
            &:hover
                background-color: rgba(187, 22, 99, 1)
                border-color: rgba(187, 22, 99, 1)
                color: $white
            &:disabled, &.btn--disabled
                background-color: rgba(255, 255, 255, 0.20)
                border-color: rgba(255, 255, 255, 0.20)
                color: $dark2
        &--secondary
            background-color: $transparent
            border-color: $white
            color: $white
            &:hover
                background-color: $white
                border-color: $white
                color: $dark2
            &:disabled, &.btn--disabled
                background-color: $transparent
                border-color: rgba(255, 255, 255, 0.20)
                color: rgba(255, 255, 255, 0.20)
        &--lg
            padding: 8px 48px
            min-height: 56px
            +max($width_xs)
                padding: 8px 32px
                min-height: 48px
        &--fluid
            width: 100%
