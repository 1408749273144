.trusted {
  &__title {
    font-size: $text-s;
    font-weight: 500;
    letter-spacing: 0.37em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;

    @include xl-max {
      flex-wrap: wrap;
      justify-content: center;
    }

    img {
      max-height: 47px;
      max-width: 215px;
      object-fit: contain;
      object-position: center;
    }
  }
}