.projects {
  .tags {
    margin-bottom: 24px;
  }

  &__slider {
    .splide__track {
      overflow: visible;
    }
  }
}

.project {
  @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 36px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(24px, 52px, 390px, 1440px, 24px, padding-bottom);

  &__top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 48px;

    @include sm-max {
      display: block;
      margin-bottom: 32px;
    }
  }

  &__image {
    flex-shrink: 0;
    width: 39%;
    border-radius: 24px;
    overflow: hidden;
    margin-right: 36px;

    @include sm-max {
      max-width: 360px;
      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    padding-block: 20px;
  }

  &__title {
    margin-bottom: 40px;

    @include sm-max {
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__inner {
    &>:not(:last-child) {
      margin-bottom: 28px;
    }

    .text {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 12px;
      }
    }
  }

  .button {
    margin-top: 40px;
  }
}