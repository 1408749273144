.recognition {
  &__grid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto;
    row-gap: 12px;
    column-gap: 12px;

    @include lg-max {
      grid-template-columns: 45% 55%;
    }

    @include sm-max {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    box-shadow: none;
    position: relative;
    color: $white;

    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: span 2;
      background-image: $gradient-second;
    }

    &:nth-child(2) {
      background-color: $black;
    }

    &:nth-child(3) {
      background-image: $gradient-main;
    }
  }

  &__image {
    img {
      width: 100%;

      @include sm-max {
        aspect-ratio: 16/9;
      }
    }
  }

  &__main {
    @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-top);
    @include fluidValue(16px, 30px, 390px, 1440px, 16px, padding-left);
    @include fluidValue(16px, 25px, 390px, 1440px, 16px, padding-right);
    padding-bottom: 24px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include fluidValue(26px, 45px, 390px, 1440px, 32px, font-size);
    margin-bottom: 20px;
  }

  .text {
    max-width: calc(100% - 60px);
  }

  .more-link {
    position: absolute;
    right: 25px;
    bottom: 28px;
    z-index: 1;

    @include sm-max {
      right: 16px;
      bottom: 16px;
    }

    &::before {
      content: '';
      width: 90%;
      height: 90%;
      border-radius: 50%;
      background-color: $black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}