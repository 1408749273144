.article-cta {
  &__image {
    img {
      width: 100%;
      aspect-ratio: 565/345;
    }
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 28px, 390px, 1440px, 16px, padding-inline);

    &>:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__title {
    @include fluidValue(36px, 50px, 390px, 1440px, 36px, font-size);

    @include sm-min {
      max-width: 430px;
    }
  }

  .text {
    @include sm-min {
      max-width: 445px;
    }
  }

  .more-link {
    margin-top: auto;
  }
}

.article-body {
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
