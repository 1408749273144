.highlights
    margin-bottom: 120px
    +max($width_md)
        margin-bottom: 80px
    +max($width_xs)
        margin-bottom: 56px
    &:first-child
        .highlights__heading
            margin-top: -16px
    &__heading
        margin-bottom: 104px
        +max($width_md)
            margin-bottom: 64px
        +max($width_xs)
            margin-bottom: 40px
    &__items
        --bs-gutter-x: 20px
        --bs-gutter-y: 64px
        margin-bottom: 72px
        +max($width_md)
            --bs-gutter-y: 40px
            margin-bottom: 56px
        +max($width_xs)
            --bs-gutter-y: 32px
            margin-bottom: 40px
        &:last-child
            margin-bottom: 0
    &__item
        &-icon
            display: flex
            justify-content: center
            align-items: center
            width: 40px
            height: 40px
            margin-bottom: 20px
            border-radius: 8px
            background: $violet
            color: $white
            box-shadow: 0 0 10px 2px rgba(135, 41, 255, 0.60)
            +max($width_xs)
                margin-bottom: 16px
            .icon
                width: 24px
                height: 24px
        &-title
            margin-bottom: 16px
            +max($width_xs)
                margin-bottom: 8px