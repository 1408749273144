.header {
  width: 100%;
  background-color: $black;
  color: $white;
  position: fixed;
  top: 0;
  z-index: 100;

  &__wrap {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding-block: 15px;
    transition: transform 0.75s ease;

    @include lg-max {
      justify-content: space-between;
    }
  }

  .logo {
    @include lg-max {
      position: relative;
      z-index: 1001;
    }
  }

  .menu {
    margin: 0 auto;
  }

  &__menu-trigger {
    display: none;

    @include lg-max {
      display: block;
      width: 40px;
      height: 40px;
      padding: 0;
      background-color: transparent;
      border: none;
      position: relative;
      cursor: pointer;
      z-index: 1001;

      &::before,
      &::after,
      span {
        content: '';
        width: 80%;
        height: 2px;
        border-radius: 2px;
        background-color: $white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &::before {
        top: 9px;
      }

      span {
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        bottom: 9px;
      }
    }
  }

  &--menuopen {
    .header__menu-trigger {
      &::before {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        top: 50%;
        bottom: initial;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      span {
        opacity: 0;
      }
    }

    .menu {
      opacity: 1;
      visibility: visible;
      transition: 0.3s ease-in-out
    }
  }
}

.menu {
  @include lg-max {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 120px;
    background-color: $black;
    z-index: 1000;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 100px;
      background-color: $black;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  &__list {
    @include list-reset;
    display: flex;
    align-items: center;

    @include lg-max {
      flex-direction: column;
      padding-inline: 18px;
      padding-bottom: 50px;

    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;

      @include lg-max {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }

  &__link {
    padding-inline: 8px;
    font-size: 16px;

    @include xl-max {
      padding-inline: 0;
    }

    @include lg-max {
      font-size: $text-xl;
    }

    @include hasHover {
      background: -webkit-linear-gradient(45deg, #2CBF5C 0%, #48DF84 48%, #C3E813 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .button {
    padding: 5px 20px;
    font-weight: 400;

    @include lg-max {
      font-size: $text-xl;
    }
  }

  .lang-switcher {
    font-size: $text-s;

    @include lg-max {
      font-size: $text-xl;
    }

    &__list {
      top: initial;
      bottom: 0;
      transform: translateY(100%);
    }
  }
}

body:not(.loaded) {
  .header__wrap {
    transform: translateY(-200px);
  }
}