.form
    &__field
        margin-bottom: 24px
    &__submit
        margin-top: 40px
        +md-max()
            margin-top: 32px
        +xs-max()
            margin-top: 24px
    &__label
        display: block
        margin-bottom: 8px
        font-size: $text-m
        font-weight: 700
        line-height: 1.2
        +xs-max()
            font-size: $text-s
    &__hint
        font-size: $text-s
        line-height: 1.4
        color: $gray
        a
            color: $accent
    &__error
        display: none
        margin-top: 8px
        font-size: $text-l
        font-weight: 500
        line-height: 1.3
        color: $warn

.form--submitted
    .form__field.form__field--error
        .form__label
            color: $warn
        input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea
            color: $warn
        .form__error
            display: block
