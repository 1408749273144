.join-team-cta {
  display: flex;
  flex-direction: column;
  @include fluidValue(24px, 60px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 48px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(24px, 42px, 390px, 1440px, 24px, padding-bottom);
  background-color: $black;
  color: $white;

  &>:not(:last-child) {
    @include fluidValue(30px, 40px, 390px, 1440px, 30px, margin-bottom);
  }

  .button {
    margin-top: auto;
  }

  &__title {
    @include fluidValue(36px, 90px, 390px, 1440px, 36px, font-size);
    letter-spacing: -0.04em;
  }
}