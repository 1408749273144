.popup
    position: fixed
    display: none
    opacity: 0
    justify-content: center
    align-items: center
    width: 100%
    height: 100vh
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: $index-5
    background: rgba(25, 25, 25, 0.50)
    transition: opacity $transition
    padding: 24px
    &.active
        display: flex
    &__wrapper
        position: relative
        display: flex
        flex-direction: column
        background: $white
        border-radius: 16px
        padding: 56px
        color: $dark2
        max-width: 630px
        max-height: 100%
        +max($width_md)
            padding: 48px
        +max($width_xs)
            padding: 40px 24px
        &.popup__wrapper--sm
            padding: 48px
            +max($width_md)
                padding: 40px
            +max($width_xs)
                padding: 32px
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6
            color: inherit
            font-weight: 700
            text-align: center
        .ss-main
            color: $dark2
        .ss-main.ss-main-light
            background: $gray
            padding: 0 10px 0 20px
            border-radius: 4px
            .ss-placeholder
                color: $light
            .ss-arrow
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 10C16.7239 9.72386 16.2761 9.72386 16 10L12 14L8 10C7.72386 9.72386 7.27614 9.72386 7 10C6.72386 10.2761 6.72386 10.7239 7 11L12 16L17 11C17.2761 10.7239 17.2761 10.2761 17 10Z' fill='rgb(64 30 108)'/%3E%3C/svg%3E%0A")
    &__close
        display: block
        width: 32px
        height: 32px
        position: absolute
        top: 24px
        right: 24px
        cursor: pointer
        +max($width_md)
            top: 20px
            right: 20px
        .icon
            width: 100%
            height: 100%
    &__title
        margin-bottom: 20px
        flex-shrink: 0
        + .popup__content
            padding-top: 20px
            +max($width_md)
                padding-top: 12px
            +max($width_xs)
                padding-top: 4px
    &__content
        flex-grow: 1
        overflow-y: auto
