.benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -4px -16px;

  @include xs-max {
    margin-bottom: -8px;
  }

  &>li {
    width: 33.33%;
    padding: 0 4px;
    margin-bottom: 16px;

    @include sm-max {
      width: 50%;
    }

    @include xs-max {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include fluidValue(24px, 35px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 24px, 390px, 1440px, 16px, padding-inline);
    border-radius: 22px;
    border: 1px solid #D9D9D9;
    background-color: $white;
    text-align: center;

    &>:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__icon {
    width: 63px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $black;
  }

  &__title {
    @include fluidValue(18px, 22px, 390px, 1440px, 18px, font-size);
    @include fluidValue(4px, 24px, 390px, 1440px, 4px, margin-top);
  }

  .text {
    font-size: $text-s;

    ul {
      @include list-reset;
    }

    li {
      display: inline-block;
      position: relative;

      &::before {
        content: '•';
        margin-right: 0.3em;
      }

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  &__descr {
    min-height: calc(2em * 1.33);
    font-weight: 500;
    @include fluidValue(0px, 8px, 390px, 1440px, 0px, margin-top);

    @include xs-max {
      min-height: auto;
    }
  }
}