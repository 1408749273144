.why-zinit {
  .tags {
    margin-bottom: 20px;
  }

  .splide__slide {
    @include fluidValue(24px, 56px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-inline);
    @include fluidValue(20px, 42px, 390px, 1440px, 20px, border-radius);
    background-color: $white;
    color: $black;
  }

  .splide__track {
    box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.09);
    border-radius: 42px;
  }

  .text {
    font-weight: 500;
    letter-spacing: -0.03em;
  }
}
