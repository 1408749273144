@import "./normalize"
@import "./vars"
@import "./mixins"
@import "./fonts"
@import "./sprite_generated"

@import "../libs/bootstrap/dist/css/bootstrap-grid.min.css"
@import "../libs/slim-select/dist/slimselect.css"
@import "../libs/perfect-scrollbar/css/perfect-scrollbar.css"
  
*
    box-sizing: border-box
    margin: 0
    padding: 0
    -webkit-tap-highlight-color: transparent
    // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()
html, body
    font-size: 18px
html
    --vh: 1vh
    --vh: 1svh
body
    display: flex
    flex-direction: column
    background: $dark
    color: $light
    font-family: $Satoshi
    line-height: 1.5
    width: 100%
    min-height: calc(var(--vh) * 100)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-weight: 400
    +max($width_md)
        font-size: $font16
    &.active
        overflow-y: hidden
    &.global
        .only-global
            display: inline-flex
        .hide-global
            display: none
    .only-global
        display: none
.container
    width: 100%
    max-width: calc(1440px + var(--bs-gutter-x))
    min-width: 320px
    margin: 0 auto
    --bs-gutter-x: 180px
    +max($width_xxl)
        --bs-gutter-x: 160px
    +max($width_lg)
        --bs-gutter-x: 128px
    +max($width_xs)
        --bs-gutter-x: 48px
iframe
    display: block
    border: none
video
    display: block
    object-fit: cover
ul
    list-style-type: none
button
    border: none
    background: transparent
    color: inherit
    text-align: left
button, input, [role="button"], textarea
    appearance: none
    outline: none
button, input, optgroup, select, textarea
    line-height: inherit
img
    max-width: 100%
    max-height: 100%
.icon
    display: block
a
    color: inherit
    text-decoration: none
    transition: $transition
.bg
    background-color: $dark2
    background-size: cover
    background-repeat: no-repeat
    background-position: left top
    &.bg
        &-main
            background-image: url(../images/bg/bg-main.png)
            +max($width_md)
                background-image: url(../images/bg/bg-main@md.png)
            +max($width_xs)
                background-image: url(../images/bg/bg-main@xs.png)
        &-buyers
            background-image: url(../images/bg/bg-buyers.png)
            +max($width_md)
                background-image: url(../images/bg/bg-buyers@md.png)
            +max($width_xs)
                background-image: url(../images/bg/bg-buyers@xs.png)
        &-suppliers
            background-image: url(../images/bg/bg-suppliers.png)
            +max($width_md)
                background-image: url(../images/bg/bg-suppliers@md.png)
            +max($width_xs)
                background-image: url(../images/bg/bg-suppliers@xs.png)
        &-about
            background-image: url(../images/bg/bg-about.png)
            +max($width_md)
                background-image: url(../images/bg/bg-about@md.png)
            +max($width_xs)
                background-image: url(../images/bg/bg-about@xs.png)
.text
    &-xs
        font-size: $font12
        line-height: 1.4
    &-sm
        font-size: $font14
        &.line-md
            line-height: 1.2
    &-md
        font-size: $font16
        line-height: 1.2
    &-center
        text-align: center
    &-left
        text-align: left
.line-md
    line-height: 1.3
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
    font-family: $Play
    margin: 0
    color: $white
h1, h2,
.h1, .h2
    font-weight: 700
    line-height: 1.2
    text-align: center
h3, h4, h5, h6,
.h3, .h4, .h5, h6
    font-weight: 400
    line-height: 1.3
h1, .h1
    text-transform: uppercase
    font-size: $font72
    +max($width_md)
        font-size: $font68
    +max($width_xs)
        font-size: $font36
h2, .h2
    font-size: $font60
    +max($width_md)
        font-size: $font44
    +max($width_xs)
        font-size: $font26
    strong, b
        font-weight: inherit
        color: #EA4C96
        background-image: linear-gradient(to right, #4C2884, #EA4C96)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        +max($width_xs)
            background-image: linear-gradient(to right, #A53D8F, #EA4C96)
h3, .h3
    font-size: $font32
    +max($width_md)
        font-size: $font28
    +max($width_xs)
        font-size: $font22
h4, .h4
    font-size: $font30
h5, .h5
    font-size: $font26
h6, .h6
    font-size: $font22
.subheading
    display: block
    font-family: $Play
    font-size: $font24
    line-height: 1.3
    text-align: center
    margin-bottom: 16px
    +max($width_md)
        font-size: $font22
    +max($width_xs)
        font-size: $font14
        margin-bottom: 8px
    &:last-child
        margin-bottom: 0
    a
        color: $white
        &:hover, &:active
            color: $pink
    span
        color: $pink
.block
    &-xl
        max-width: 1043px
        margin-left: auto
        margin-right: auto
article
    padding-bottom: 120px
    +max($width_md)
        padding-bottom: 80px
    +max($width_xs)
        padding-bottom: 56px
    h2
        margin-bottom: 104px
        +max($width_md)
            margin-bottom: 56px
        +max($width_xs)
            margin-bottom: 40px
    h3
        margin-top: 64px
        margin-bottom: 16px
        +max($width_md)
            margin-top: 40px
        +max($width_xs)
            margin-top: 32px
    p, ul, ol
        margin-bottom: 26px
        &:last-child
            margin-bottom: 0
    li
        padding-left: 26px
        position: relative
        &::before
            content: ""
            display: block
            width: 5px
            height: 5px
            border-radius: 50%
            background: currentColor
            position: absolute
            top: 12px
            left: 10px
            +max($width_xs)
                top: 10px
    a:not([class])
        text-decoration: underline solid currentColor 1.5px
        text-underline-offset: .2em
        &:hover, &:active
            color: $pink
.hide
    display: none !important
