.hero {
  padding-top: 45px !important;

  &__wrap {
    text-align: center;
  }

  &__caption {
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.37em;
    margin-bottom: 30px;
  }

  &__title {
    text-transform: capitalize;

    &--s {
      @include fluidValue(40px, 70px, 390px, 1440px, 40px, font-size)
    }
  }

  .text {
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, font-size);
    font-weight: 500;
    letter-spacing: -0.02em;
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    row-gap: 12px;
    margin-top: 20px;

    @include sm-max {
      flex-wrap: wrap;
      column-gap: 12px;
    }

    .button {
      @include fluidValue(14px, 20px, 390px, 1440px, 14px, padding-block);
      @include fluidValue(20px, 36px, 390px, 1440px, 20px, padding-inline);
      @include fluidValue(16px, 28px, 390px, 1440px, 16px, font-size);
      font-weight: 600;
    }
  }
}