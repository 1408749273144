.sales {
  &__title {
    @include fluidValue(40px, 70px, 390px, 1440px, 40px, font-size);
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: center;
  }

  &__intro {
    display: block;
    max-width: 915px;
    @include fluidValue(24px, 30px, 390px, 1440px, 24px, font-size);
    font-weight: 500;
    text-align: center;
    margin: 12px auto 24px;
  }
}

.sales-card {
  display: flex;
  flex-direction: column;
  @include fluidValue(24px, 36px, 390px, 1440px, 24px, padding-block);
  @include fluidValue(16px, 36px, 390px, 1440px, 16px, padding-left);
  @include fluidValue(16px, 22px, 390px, 1440px, 16px, padding-right);

  &>:not(:last-child) {
    @include fluidValue(16px, 24px, 390px, 1440px, 16px, margin-bottom);
  }

  &__image {
    @include fluidValue(100px, 175px, 390px, 1440px, 100px, width);
    @include fluidValue(110px, 182px, 390px, 1440px, 110px, height);
    border-radius: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    a {
      transition: color 0.25s;

      &:hover {
        color: $blue;
      }
    }
  }

  &__position {
    display: block;
    max-width: 280px;
    font-size: $text-l;
    font-weight: 500;
    margin-top: 4px;

    @include sm-max {
      max-width: none;
    }
  }

  &__link {
    font-size: $text-s;
    margin-top: auto;
    transition: color 0.25s;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: translateY(4px);
      margin-right: 10px;
      background-image: url(./../images/sprites/main.stack.svg#image-linkedin-colored);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &:hover {
      color: $blue;
    }
  }
}