.form
    &__field
        margin-bottom: 24px
    &__submit
        margin-top: 40px
        +max($width_md)
            margin-top: 32px
        +max($width_xs)
            margin-top: 24px
    &__label
        display: block
        margin-bottom: 8px
        font-size: $font14
        font-weight: 700
        line-height: 1.2
        +max($width_xs)
            font-size: $font12
    &__hint
        font-size: $font12
        line-height: 1.4
        color: $light
        a
            color: $pink
    &__error
        display: none
        margin-top: 8px
        font-size: $font16
        font-weight: 500
        line-height: 1.3
        color: $red

.form--submitted
    .form__field.form__field--error
        .form__label
            color: $red
        input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea
            color: $red
        .form__error
            display: block
