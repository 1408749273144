.playbook {
  &__image {
    img {
      width: 100%;
      aspect-ratio: 565/315;
    }
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $black;
    color: $white;
    @include fluidValue(24px, 50px, 390px, 1440px, 24px, padding-top);
    @include fluidValue(16px, 50px, 390px, 1440px, 16px, padding-inline);
    @include fluidValue(20px, 45px, 390px, 1440px, 20px, padding-bottom);

    &>:not(:last-child) {
      @include fluidValue(20px, 36px, 390px, 1440px, 20px, margin-bottom);
    }

    .button {
      margin-top: auto;
    }
  }

  &__title {
    letter-spacing: -0.03em;
  }
}