
.popup {
  position: fixed;
  display: none;
  opacity: 0;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(25,25,25,.5);
  transition: opacity .2s ease-in;
  padding: 24px
}
.popup.active {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}
.popup__wrapper {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 16px;
  padding: 56px;
  color: #401e6c;
  max-width: 630px;
  max-height: 100%
}
@media (max-width:991.98px) {
  .popup__wrapper {
    padding: 48px
  }
}
@media (max-width:575.98px) {
  .popup__wrapper {
    padding: 40px 24px
  }
}
.popup__wrapper.popup__wrapper--sm {
  padding: 48px
}
@media (max-width:991.98px) {
  .popup__wrapper.popup__wrapper--sm {
    padding: 40px
  }
}
@media (max-width:575.98px) {
  .popup__wrapper.popup__wrapper--sm {
    padding: 32px
  }
}
.popup__wrapper .h1,
.popup__wrapper .h2,
.popup__wrapper .h3,
.popup__wrapper .h4,
.popup__wrapper .h5,
.popup__wrapper .h6,
.popup__wrapper h1,
.popup__wrapper h2,
.popup__wrapper h3,
.popup__wrapper h4,
.popup__wrapper h5,
.popup__wrapper h6 {
  color: inherit;
  font-weight: 700;
  text-align: center
}
.popup__wrapper .ss-main {
  color: #401e6c
}
.popup__wrapper .ss-main.ss-main-light {
  background: #e2e2e9;
  padding: 0 10px 0 20px;
  border-radius: 4px
}
.popup__wrapper .ss-main.ss-main-light .ss-placeholder {
  color: #9c84bc
}
.popup__wrapper .ss-main.ss-main-light .ss-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 10C16.7239 9.72386 16.2761 9.72386 16 10L12 14L8 10C7.72386 9.72386 7.27614 9.72386 7 10C6.72386 10.2761 6.72386 10.7239 7 11L12 16L17 11C17.2761 10.7239 17.2761 10.2761 17 10Z' fill='rgb(64 30 108)'/%3E%3C/svg%3E%0A")
}
.popup__close {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer
}
@media (max-width:991.98px) {
  .popup__close {
    top: 20px;
    right: 20px
  }
}
.popup__close .icon {
  width: 100%;
  height: 100%
}
.popup__title {
  margin-bottom: 20px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}
.popup__title + .popup__content {
  padding-top: 20px
}
@media (max-width:991.98px) {
  .popup__title + .popup__content {
    padding-top: 12px
  }
}
@media (max-width:575.98px) {
  .popup__title + .popup__content {
    padding-top: 4px
  }
}
.popup__content {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-y: auto
}
