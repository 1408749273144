.person {
  display: flex;

  &__image {
    flex-shrink: 0;
    @include fluidValue(54px, 78px, 390px, 1440px, 54px, width);
    @include fluidValue(54px, 78px, 390px, 1440px, 54px, height);
    border-radius: 50%;
    overflow: hidden;
    @include fluidValue(8px, 16px, 390px, 1440px, 8px, margin-right);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    align-self: center;
    font-size: $text-s;
  }

  &__name {
    display: block;
    font-weight: 700;
  }

  &__position {
    display: block;
  }
}