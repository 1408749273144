.team {
  &__card {
    flex-direction: row;
    column-gap: 12px;
    @include fluidValue(24px, 32px, 390px, 1440px, 24px, padding-top);
    @include fluidValue(16px, 30px, 390px, 1440px, 16px, padding-right);
    @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-left);
    @include fluidValue(20px, 30px, 390px, 1440px, 20px, padding-bottom);

    @include sm-max {
      flex-direction: column;
    }
  }

  &__main {
    width: 50%;
    display: flex;
    flex-direction: column;

    @include sm-max {
      width: auto;
    }

    &>:not(:last-child) {
      margin-bottom: 30px;
    }

    .button {
      margin-top: auto;
    }
  }

  &__title {
    @include fluidValue(26px, 36px, 390px, 1440px, 26px, font-size);
  }

  &__media {
    align-self: flex-start;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    @include sm-max {
      width: auto;
      order: -1;
      margin: 0 -3px 30px;
    }
  }

  &__image {
    width: 25%;
    padding: 0 6px;

    @include sm-max {
      padding: 0 3px 6px;
    }

    @include xs-max {
      width: 50%;
    }

    &:first-child {
      width: 100%;
      @include fluidValue(6px, 26px, 390px, 1440px, 6px, margin-bottom);

      img {
        @include fluidValue(12px, 24px, 390px, 1440px, 12px, border-radius);
      }
    }

    img {
      width: 100%;
      border-radius: 7px;
    }
  }
}