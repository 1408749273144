.how-it-is {
  @include fluidValue(24px, 46px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 46px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 30px, 390px, 1440px, 20px, padding-bottom);

  &>:not(:last-child) {
    margin-bottom: 24px;
  }

  &__title {
    @include fluidValue(40px, 64px, 390px, 1440px, 40px, font-size);
  }

  .text {
    font-weight: 500;

    li:not(:last-child) {
      margin-bottom: 0.75em;
    }
  }

  &:first-child {
    background-color: $black;
    color: $white;
  }

  &:last-child {
    background-image: $gradient-main;

    .how-it-is__title {
      color: #fff;
    }
  }
}