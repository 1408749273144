.offices {
  .section-title {
    @include fluidValue(36px, 42px, 390px, 1440px, 36px, font-size);
  }

  .tags {
    margin-bottom: 24px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 12px;
    row-gap: 12px;

    @include lg-max {
      grid-template-columns: 60% 40%;
    }

    @include sm-max {
      grid-template-columns: 100%;
    }
  }

  &__title {
    font-weight: 500;
  }

  &__data {
    margin-top: 10px;

    li {
      display: inline-block;

      &:not(:last-child):after {
        content: '  |  ';
      }
    }

    a:hover {
      color: $accent;
    }
  }

  .splide__track {
    height: 100%;
  }

  .splide__slide {
    &::after {
      content: '';
      width: 100%;
      height: 50%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 55%);
      opacity: 0.75;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      @include sm-max {
        height: 75%;
      }
    }

    picture {
      height: 100%;
    }

    img {
      aspect-ratio: 800/525;
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    color: #fff;
    @include fluidValue(24px, 32px, 390px, 1440px, 16px, padding-block);
    @include fluidValue(16px, 40px, 390px, 1440px, 16px, padding-inline);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}