.solution
    margin-bottom: 120px
    +max($width_md)
        margin-bottom: 80px
    +max($width_xs)
        margin-bottom: 56px
    &__heading
        margin-bottom: 155px
        +max($width_md)
            margin-bottom: 128px
        +max($width_xs)
            margin-bottom: 76px
        + .solution__items
            margin-top: calc(var(--bs-gutter-y) * -1 - 12px)
            +max($width_md)
                margin-top: calc(var(--bs-gutter-y) * -1 - 48px)
            +max($width_xs)
                margin-top: calc(var(--bs-gutter-y) * -1 - 7px)
    &__ai
        margin-bottom: 262px
        +max($width_md)
            margin-bottom: 188px
        +max($width_xs)
            margin-bottom: 125px
    &__items
        --bs-gutter-y: 61px
        --bs-gutter-x: 21px
        margin-bottom: 72px
        position: relative
        z-index: $index-1
        +max($width_md)
            --bs-gutter-x: 16px
            margin-bottom: 56px
        +max($width_xs)
            margin-bottom: 40px
        &:last-child
            margin-bottom: 0
    &__item
        display: flex
        flex-direction: column
        padding: 65px 30px 40px
        border-radius: 16px
        background: #291346
        background-clip: padding-box
        border: 1px solid $transparent
        position: relative
        color: $white
        text-align: center
        height: 100%
        +max($width_md)
            padding: 48px 16px 24px
        +max($width_xs)
            padding: 41px 24px 32px
        &::before, &::after
            content: ""
            display: block
            position: absolute
            z-index: -1
        &::before
            top: 0
            right: 0
            bottom: 0
            left: 0
            margin: -1px
            border-radius: inherit
            background: linear-gradient(to bottom, $transparent, rgba(156, 91, 222, 0.3))
        &::after
            width: 40%
            top: 0
            bottom: 0
            left: 50%
            transform: translateX(-50%)
            border-radius: 32px
            box-shadow: 0 0 100px 70px rgba(182, 114, 251, .14)
        &.solution__item--alt
            +max($width_xs)
                padding: 31px 24px 32px
            .solution__item-title
                margin-bottom: 31px
                font-size: $font24
                +max($width_md)
                    font-size: $font14
                +max($width_xs)
                    margin-bottom: 14px
                    font-size: $font22
        &-wrapper
            &:nth-child(2)
                .solution__item-index
                    background: $pink
            &:nth-child(3)
                .solution__item-index
                    background: $violet
        &-index
            position: absolute
            top: 0
            left: 50%
            transform: translate(-50%,-50%)
            width: 48px
            height: 48px
            display: flex
            align-items: center
            justify-content: center
            font-weight: 500
            font-size: $font16
            line-height: 1.3
            border-radius: 50%
            background: $lavender
            +max($width_md)
                width: 32px
                height: 32px
                font-size: $font12
                font-weight: 400
                line-height: 1.4
            +max($width_xs)
                width: 36px
                height: 36px
            &::before
                content: ""
                display: block
                width: 72px
                height: 72px
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
                background: inherit
                opacity: .2
                z-index: -1
                border-radius: 50%
                +max($width_md)
                    width: 48px
                    height: 48px
                +max($width_xs)
                    width: 54px
                    height: 54px
        &-title
            font-family: $Play
            line-height: 1.3
            font-size: $font32
            margin-bottom: 40px
            position: relative
            z-index: $index-2
            +max($width_md)
                margin-bottom: 32px
                font-size: $font22
                line-height: 1.2
        &-image
            margin-top: auto
            position: relative
            z-index: $index-1
            &::before
                content: ""
                display: block
                position: absolute
                z-index: -1
                width: 40%
                top: 0
                bottom: 0
                left: 50%
                transform: translateX(-50%)
                border-radius: 32px
                box-shadow: 0 0 100px 20px rgba(135, 41, 253, .6)
            img
                display: block
                width: 100%