.roadmap {
  @include fluidValue(24px, 30px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 32px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 30px, 390px, 1440px, 20px, padding-bottom);

  &__title {
    @include fluidValue(32px, 36px, 390px, 1440px, 32px, font-size);
    margin-bottom: 24px;
  }

  &__timeline {
    font-size: $text-l;
    font-weight: 500;

    li:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__year {
    display: block;
    font-weight: 700;
  }
}