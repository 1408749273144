.join {
  @include fluidValue(24px, 70px, 390px, 1440px, 24px, padding-top);
  @include fluidValue(16px, 42px, 390px, 1440px, 16px, padding-inline);
  @include fluidValue(20px, 40px, 390px, 1440px, 20px, padding-bottom);

  &>:not(:last-child) {
    margin-bottom: 20px;
  }

  &__title {
    span {
      display: block;
      @include fluidValue(40px, 96px, 390px, 1440px, 40px, font-size)
    }
  }

  .person {
    &__main {
      font-size: $text-m;
    }

    &__name {
      font-weight: 500;
    }
  }

  .button {
    margin-top: auto;
  }
}