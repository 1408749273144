.about {
  .section-title {
    @include fluidValue(36px, 42px, 390px, 1440px, 36px, font-size);
  }

  &__wrap {
    @include fluidValue(32px, 65px, 390px, 1440px, 24px, padding-top);
    @include fluidValue(16px, 55px, 390px, 1440px, 16px, padding-inline);
    @include fluidValue(32px, 55px, 390px, 1440px, 20px, padding-bottom);
  }

  &__title {
    max-width: 910px;
    @include fluidValue(24px, 38px, 390px, 1440px, 24px, font-size);
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    text-align: center;
    margin: 0 auto 40px;
  }

  &__row {
    display: flex;
    align-items: center;
    column-gap: 32px;
    row-gap: 24px;

    @include sm-max {
      align-items: flex-start;
      flex-direction: column;
    }

    &:nth-child(even) {
      @include sm-min {
        flex-direction: row-reverse;
      }
    }

    &:not(:last-child) {
      @include fluidValue(30px, 50px, 390px, 1440px, 30px, margin-bottom);
    }
  }

  &__image {
    flex-shrink: 0;
    width: 38.5%;
    border-radius: 22px;
    overflow: hidden;

    @include sm-max {
      order: -1;
      max-width: 400px;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .text {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include fluidValue(20px, 28px, 390px, 1440px, 20px, font-size);
      font-weight: 500;
      letter-spacing: -0.03em;
      margin-bottom: 4px;
    }
  }

  .button {
    margin-top: 30px;

    @include sm-min {
      min-width: 390px;
      padding-block: 18px;
      @include fluidValue(20px, 28px, 390px, 1440px, 20px, font-size);
      font-weight: 600;
      letter-spacing: -0.02em;
    }
  }
}