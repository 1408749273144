.testimonials {
  &__card {
    display: flex;
    flex-direction: column;
    @include fluidValue(24px, 32px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 36px, 768px, 1440px, 16px, padding-inline);
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.03);

    @include lg-max {
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.03);
    }

    &>:not(:last-child) {
      margin-bottom: 20px;
    }

    .text {
      line-height: 1.66;
    }
  }

  &__title {
    max-width: 280px;
    @include fluidValue(20px, 28px, 390px, 1440px, 20px, font-size);
  }

  .text {
    line-height: 1.375;
  }

  .text ol:not(:last-child),
  .text p:not(:last-child),
  .text ul:not(:last-child) {
    margin-bottom: 0;
  }

  .person {
    margin-top: auto;
  }
}