//media
@mixin xxl-min {
  @media (min-width: #{$width_xxl + 1}) {
    @content;
  }
}

@mixin xxl-max {
  @media (max-width: $width_xxl) {
    @content;
  }
}

@mixin xl-min {
  @media (min-width: #{$width_xl + 1}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$width_xl + 1}) and (max-width: $width_xxl) {
    @content;
  }
}

@mixin xl-max {
  @media (max-width: $width_xl) {
    @content;
  }
}

@mixin lg-min {
  @media (min-width: #{$width_lg + 1}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$width_lg + 1}) and (max-width: $width_xl) {
    @content;
  }
}

@mixin lg-max {
  @media (max-width: $width_lg) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: $width_md) {
    @content;
  }
}

@mixin sm-min {
  @media (min-width: #{$width_sm + 1}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$width_sm + 1}) and (max-width: $width_lg) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: $width_sm) {
    @content;
  }
}

@mixin xs-min {
  @media (min-width: #{$width_xs + 1}) {
    @content;
  }
}

@mixin xs {
  @media (min-width: #{$width_xs + 1}) and (max-width: $width_sm) {
    @content;
  }
}

@mixin xs-max {
  @media (max-width: $width_xs) {
    @content;
  }
}

@mixin xxs-min {
  @media (min-width: #{$width_xxs + 1}) {
    @content;
  }
}

@mixin xxs-max {
  @media (max-width: $width_xxs) {
    @content;
  }
}

// fonts
@mixin font-face($font-name, $font-file, $font-weight) {
  @font-face {
    font-family: '#{$font-name}';
    font-display: swap;
    font-weight: #{$font-weight};
    src: url('../fonts/#{$font-file}.woff2') format('woff2'),
    url('../fonts/#{$font-file}.woff') format('woff')
  }
}

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin link-reset {
  text-decoration: none;
  color: inherit;
}

@mixin hasHover {
  @media (hover: hover) {
    &:hover {
      @content
    }
  }
}


@function strip-unit($number) {
  @if type-of($number)=='number'and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function calcFluidVal($f-min, $f-max, $w-min, $w-max, $units: px) {
  $f-min: strip-unit($f-min);
  $f-max: strip-unit($f-max);
  $w-min: strip-unit($w-min);
  $w-max: strip-unit($w-max);

  $k: ($f-max - $f-min) / ($w-max - $w-min);
  $b: $f-min - $k * $w-min;

  @return calc(#{$k} * 100vw + #{$b}#{$units});
}

@mixin fluidValue($val-min, $val-max, $w-min, $w-max, $fallback: false, $property) {
  $value: $val-min;

  #{$property}: $value;

  @media (min-width: $w-min) {
    @if ($fallback) {
      $value: $fallback;
    }

    else {
      $value: calcFluidVal($val-min, $val-max, $w-min, $w-max, px);
    }

    #{$property}: $value;
  }

  @media (min-width: $w-max) {
    $value: $val-max;
    #{$property}: $value;
  }
}