.heads {
  .section-title {
    @include fluidValue(36px, 42px, 390px, 1440px, 36px, font-size);
  }

  &__inner {
    @include fluidValue(32px, 42px, 390px, 1440px, 24px, padding-block);
    @include fluidValue(16px, 50px, 390px, 1440px, 16px, padding-inline);
    background-color: $white;
  }

  // &__list {
  //   @include list-reset;
  //   display: flex;
  //   flex-wrap: wrap;
  //   margin: 0 -13px -30px;

  //   @include sm-max {
  //     margin-left: 0;
  //     margin-right: 0;
  //   }
  // }

  &__item {
    width: 33.33%;
    padding: 0 13px;
    margin-bottom: 30px;

    @include lg-max {
      width: 50%;
    }

    @include sm-max {
      width: 100%;
      padding: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      @include lg-min {
        width: 50%;

        .head-card {
          flex-direction: row;

          &__image {
            margin-bottom: 0;
            @include fluidValue(16px, 26px, 390px, 1440px, 16px, margin-right);
          }

          &__main {
            text-align: left;
          }

          &__name {
            @include fluidValue(24px, 36px, 390px, 1440px, 24px, font-size);
          }

          &__position {
            font-size: $text-l;
            margin-top: 16px;
          }
        }
      }
    }
  }

  &__founders {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    max-width: 900px;
    width: 100%;
    margin: 0 auto 56px;

    @include sm-max {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 12px;
      row-gap: 30px;
      margin-bottom: 30px;
    }

    @include xs-max {
      grid-template-columns: 1fr;
    }

    .head-card {
      @include sm-min {
        flex-direction: row;

        &__image {
          margin-bottom: 0;
          @include fluidValue(16px, 26px, 390px, 1440px, 16px, margin-right);
        }

        &__main {
          text-align: left;
        }

        &__title {
          @include fluidValue(24px, 36px, 390px, 1440px, 24px, font-size);
        }

        &__position {
          font-size: $text-l;
          margin-top: 16px;
        }
      }
    }
  }

  &__list {
    @include sm-max {
      grid-template-columns: 1fr 1fr;
      row-gap: 30px;
    }

    @include xs-max {
      grid-template-columns: 1fr;
    }
  }
}

.head-card {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__image {
    flex-shrink: 0;
    @include fluidValue(140px, 200px, 390px, 1440px, 140px, width);
    @include fluidValue(140px, 200px, 390px, 1440px, 140px, height);
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 38px;
    @include fluidValue(20px, 38px, 390px, 1440px, 20px, margin-bottom);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    text-align: center;
  }

  &__title {
    @include fluidValue(20px, 26px, 390px, 1440px, 20px, font-size);
    font-weight: 600;
    letter-spacing: -0.03em;
  }

  &__position {
    display: inline-block;
    font-weight: 500;
    transition: color 0.25s;
    position: relative;
    margin-top: 8px;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: translateY(0.15em);
      margin-right: 8px;
      background-image: url(./../images/sprites/main.stack.svg#image-linkedin-colored);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &:hover {
      color: $blue;
    }
  }
}

.person-card {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    @include sm-max {
      padding-left: 0;
      margin-bottom: 16px;
    }
  }

  &__image {
    flex-shrink: 0;
    @include fluidValue(80px, 120px, 390px, 1440px, 80px, width);
    @include fluidValue(80px, 120px, 390px, 1440px, 80px, height);
    border-radius: 16px;
    overflow: hidden;
    @include fluidValue(16px, 32px, 390px, 1440px, 16px, margin-right);
  }

  &__title {
    @include fluidValue(20px, 26px, 390px, 1440px, 20px, font-size);
    font-weight: 600;
    letter-spacing: -0.03em;
  }

  &__position {
    display: block;
    @include fluidValue(16px, 18px, 390px, 1440px, 16px, font-size);
    font-weight: 500;
    margin-top: 8px;
  }
}